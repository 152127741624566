import { NamedIcon } from "@fwd-dep/nextgen-ui-lib";
import { FC } from "react";
import styled from "styled-components";
import { isExternalUrl } from "utils/string";

const Image = styled.img<{ size?: number }>`
  object-fit: contain;
  max-width: ${(p) => p.size || 16}px;
  max-height: ${(p) => p.size || 16}px;
`;

const SvgIconWrapper = styled.div<{ size?: number; color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    width: ${(p) => p.size || 16}px;
    height: ${(p) => p.size || 16}px;
  }
  ${(p) => p.color && `color: ${p.color};`}
`;

const ImageWrapper = styled.div<{ size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(p) => p.size || 16}px;
  height: ${(p) => p.size || 16}px;
`;

export interface IconProps {
  icon?: string;
  size?: number;
  color?: string;
  imageProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}

export const Icon: FC<IconProps> = (props) => {
  const { icon, size, color, imageProps } = props;

  if (!icon) {
    return null;
  }

  const isImageIcon = isExternalUrl(icon) || icon.includes("/images/v3/");

  return isImageIcon ? (
    <ImageWrapper className="icon-wrapper" size={size}>
      <Image src={icon} size={size} {...imageProps} />
    </ImageWrapper>
  ) : (
    <SvgIconWrapper className="icon-wrapper" size={size} color={color}>
      <NamedIcon icon={icon} />
    </SvgIconWrapper>
  );
};
