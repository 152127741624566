export enum FormSummaryDesignVariant {
  Variant1 = "variant1",
  Variant2 = "variant2",
}

export interface CmsFormSummarySmallTextBlock {
  small_text: {
    text: string;
    field_key: string;
    column_span: number;
  };
}

export interface CmsFormSummaryFileFieldBlock {
  file_upload_field: {
    files: {
      display_format: string;
      label: string;
      file_icon: {
        url: string;
      };
      key_name: string;
    }[];
  };
}

export interface CmsFormSummaryMultiColumnBlock {
  multi_column_layout: {
    disable_row_span_on_mobile: boolean;
    number_of_columns: number;
    gap: {
      desktop: number;
      mobile: number;
    };
    columns: CmsFormSummaryBlocks[];
  };
}

export interface CmsFormSummarySection {
  section: {
    title: string;
    form_step_key: string;
    blocks: [CmsFormSummaryBlocks];
  };
}

export interface CmsFormSummaryDivider {
  divider: {
    color: string;
    key?: string;
  };
}

export interface CmsFormSummaryTextField {
  text_field: {
    key_name: string;
    bold: boolean;
    size: "regular" | "medium";
    label: string;
  };
}

export type CmsFormSummaryBlock =
  | CmsFormSummarySmallTextBlock
  | CmsFormSummaryFileFieldBlock
  | CmsFormSummaryMultiColumnBlock
  | CmsFormSummarySection
  | CmsFormSummaryDivider
  | CmsFormSummaryTextField;

export interface CmsFormSummaryBlocks {
  blocks: CmsFormSummaryBlock[];
}

export interface CmsFormSummary {
  section_key: string;
  display_title: {
    title: string;
    icon: string;
    description: string;
  };
  form_config: FormRule;
  design_variant: FormSummaryDesignVariant;
  block: [CmsFormSummaryBlocks];
}

export interface FormSummaryFileField {
  fileUploadField: {
    designSettings?: "default" | "variant1";
    files: {
      icon: string;
      keyName: string;
      label: string;
      displayFormat: string;
    }[];
  };
}

export interface FormSummaryTextField {
  textField: {
    keyName: string;
    bold: boolean;
    size: "regular" | "medium";
  };
}

export interface FormSummarySmallTextField {
  smallText: {
    keyName: string;
    text: string;
    columnSpan?: number;
  };
  columnIndex?: number;
}

export interface FormSummaryColumnDivider {
  columnDivider: {
    size: string;
  };
}

export interface FormSummaryMultiColumnLayout {
  multiColumnLayout: {
    numberOfColumns: number;
    crossColumnRowAligned: boolean;
    disableRowSpanOnMobile: boolean;
    gap: {
      desktop: number;
      mobile: number;
    };
    columns: {
      blocks: FormSummaryBlock[];
      size: string;
      isDivider?: boolean;
    }[];
  };
}

export interface FormSummaryLayoutDivider {
  divider: {
    color: string;
    keyName: string;
  };
}

export interface FormSummarySection {
  section: {
    title?: string;
    sectionKey?: string;
    sectionUrl?: string;
    blocks: FormSummaryBlock[];
  };
}

export interface FormSummaryParagraph {
  paragraph: {
    text: string;
  };
}
export interface FormRule {
  [K: string]: {
    visible:
    | {
      fieldKey: string;
      value: string;
    }[]
    | boolean;
  };
}

export type FormSummaryBlock =
  | FormSummarySection
  | FormSummaryLayoutDivider
  | FormSummaryFileField
  | FormSummarySmallTextField
  | FormSummaryMultiColumnLayout
  | FormSummaryColumnDivider
  | FormSummaryTextField
  | FormSummaryParagraph;

export function isFormSummaryLayoutDivider(
  block: FormSummaryBlock
): block is FormSummaryLayoutDivider {
  return (block as FormSummaryLayoutDivider).divider !== undefined;
}

export function isFormSummarySmallTextField(
  block: FormSummaryBlock
): block is FormSummarySmallTextField {
  return (block as FormSummarySmallTextField).smallText !== undefined;
}

export function isFormSummaryMultiColumnLayout(
  block: FormSummaryBlock
): block is FormSummaryMultiColumnLayout {
  return (
    (block as FormSummaryMultiColumnLayout).multiColumnLayout !== undefined
  );
}

export function isFormSummaryColumnDivider(
  block: FormSummaryBlock
): block is FormSummaryColumnDivider {
  return (block as FormSummaryColumnDivider).columnDivider !== undefined;
}

export function isFormSummarySection(
  block: FormSummaryBlock
): block is FormSummarySection {
  return (block as FormSummarySection).section !== undefined;
}

export function isFormSummaryFileField(
  block: FormSummaryBlock
): block is FormSummaryFileField {
  return (block as FormSummaryFileField).fileUploadField !== undefined;
}

export function isFormSummaryTextField(
  block: FormSummaryBlock
): block is FormSummaryTextField {
  return (block as FormSummaryTextField).textField !== undefined;
}

export function isFormSummaryParagraph(
  block: FormSummaryBlock
): block is FormSummaryParagraph {
  return (block as FormSummaryParagraph).paragraph !== undefined;
}

export type FormSummaryProps = {
  blocks: FormSummaryBlock[];
  sectionKey?: string;
  sectionUrl?: string;
  designVariant: FormSummaryDesignVariant;
  formConfig: object;
  title?: {
    title: string;
    icon?: string;
    description: string;
    action?: {
      icon?: string;
      label?: string;
      onClick: Function;
      url?: string;
    };
  };
};
