import eventBus from "services/event-bus"
export function _getCTAEventName(ctaUid: string) {
  return `cta:${ctaUid}`;
}
export function registerCTAHandler(
  ctaUid: string,
  handler: EventListenerOrEventListenerObject,
  customEventName?: string
) {
  const eventName = customEventName || _getCTAEventName(ctaUid);

  eventBus.on(eventName, handler);
  return () => eventBus.off(eventName, handler);
}

export function _dispatchCTAEvent(ctaUid: string, customEventName?: string) {
  const eventName = customEventName || _getCTAEventName(ctaUid);

  eventBus.dispatch(eventName)
}

export function _dispatchCustomCTAEvent(customEventName: string) {
  const eventName = `cta:${customEventName}`;

  eventBus.dispatch(eventName)
}
