import { getWindow, replaceContentStackURL } from "@utils";
import {
  removeDocument,
  uploadDocument,
} from "components/UploadDocument/services/uploadDocument.service";
import { I18nContextData } from "i18n/context/LanguageContext";
import get from "lodash/get";
import { formatImageToPicture } from "utils/format";
import { resolveAbsoluteUrl, resolveAbsoluteUrlInParagraph } from "utils/route";
import { toKebabCase } from "utils/string";
import {
  ClaimFormTypeEnum,
  CmsFormSubmitBlock,
  CSFormButtonBlock,
  CSFormDocumentInfoBlock,
  CSFormFreeTextBlock,
  CSFormIntroductionBlock,
  CSFormMarketingBlockBlock,
  CSFormSectionTitle,
  CSFormUploadDocumentBlock,
  CSFormUploadNextButton,
  CSSummaryBlock,
} from "../types/contentstack.types";
import {
  FlexibleFButtomBlock,
  FlexibleFormBlockType,
  FlexibleFormConditionalBlock,
  FlexibleFormConditionalDisplayBlock,
  FlexibleFormDocumentInfoBlock,
  FlexibleFormFreeTextBlock,
  FlexibleFormIntroductionBlock,
  FlexibleFormSectionTitleBlock,
  FlexibleFormSubmitBlock,
  FlexibleFormSummaryBlock,
  FlexibleFormUploadDocumentBlock,
  FlexibleFormUploadNextButton,
  FlexibleFormUSPBlock,
  NextGenFlexibleFormApi,
} from "../types/flexibleform.types";
import { CmsFormSummary } from "../types/formSummary.type";
import { transformFormSummaryBlock } from "./transformFormSummary";
import {
  dismissPopup,
  showPopup,
  translatePopupProps,
} from "shared-components/Popup/Popup.service";
import { transformFormBlocks } from "./transform";
import axios from "axios";
import { PageEclaim } from "constants/pages.constants";
import { getRequestUrl } from "page-services/helper.service";
import { generateUniqSerial } from "utils/uid";
import { checkFieldShouldSubmit, checkShouldReplaceValue } from "../utils";

const allowedStyleProps = [
  "margin",
  "marginTop",
  "marginBottom",
  "margin-bottom",
  "margin-top",
];

function transformStyle(originStyle: Object) {
  const outputStyle = {};
  for (let styleProp of allowedStyleProps) {
    if (originStyle[styleProp] !== undefined) {
      outputStyle[styleProp] = originStyle[styleProp];
    }
  }

  return outputStyle;
}

export function transformIntroductionBlock(
  block: CSFormIntroductionBlock
): FlexibleFormIntroductionBlock {
  const picSrc = replaceContentStackURL(block.icon?.url);
  return {
    type: FlexibleFormBlockType.IntroductionBlock,
    title: block.heading,
    picture: picSrc ? formatImageToPicture(picSrc).media : null,
    style: transformStyle(block.custom_styling ?? {}),
  };
}

export function transformUSPBlock(
  block: CSFormMarketingBlockBlock
): FlexibleFormUSPBlock {
  const picSrc = replaceContentStackURL(block.banner_image?.url);

  return {
    type: FlexibleFormBlockType.USPBlock,
    title: block.title,
    subtitle: block.description,
    image: picSrc ? formatImageToPicture(picSrc).media : null,
    items: block.usp.map((item) => {
      const itemPic = item.usp_icon?.url;
      return {
        picture: itemPic ? formatImageToPicture(itemPic).media : null,
        title: item.usp_title,
        text: item.usp_description,
      };
    }),
    style: transformStyle(block.custom_styling ?? {}),
  };
}

export function transformSectionTitleBlock(
  block: CSFormSectionTitle
): FlexibleFormSectionTitleBlock {
  const picSrc = replaceContentStackURL(block.icon?.url);
  return {
    type: FlexibleFormBlockType.SectionTitle,
    picture: picSrc ? formatImageToPicture(picSrc).media : null, //block.icon,
    title: block.title,
    style: {}, // transformStyle(block. ?? {})
  };
}

export function transformButtonBlock(
  block: CSFormButtonBlock
): FlexibleFButtomBlock {
  return {
    type: FlexibleFormBlockType.ButtonBlock,
    title: block.button_label,
    icon: block.icon,
    style: transformStyle(block.style ?? {}),
    url: block.url || "",
  };
}

export function transformTextBlock(
  block: CSFormFreeTextBlock
): FlexibleFormFreeTextBlock {
  const { design_settings = { top_margin: "", bottom_margin: "" } } = block;
  const { top_margin, bottom_margin } = design_settings;

  return {
    type: FlexibleFormBlockType.FreeTextBlock,
    text: block.text_block,
    style: transformStyle(
      block.style ?? {
        marginTop:
          top_margin && !Number.isNaN(top_margin) ? `${top_margin}px` : "16px",
        marginBottom:
          bottom_margin && !Number.isNaN(bottom_margin)
            ? `${bottom_margin}px`
            : "16px",
      }
    ),
  };
}

export function transformConditionBlock(
  block: any
): FlexibleFormConditionalBlock {
  const { design_settings = { top_margin: "", bottom_margin: "" } } = block;
  const { top_margin, bottom_margin } = design_settings;

  return {
    type: FlexibleFormBlockType.ConditionalBlock,
    sessionStorageKey: get(block, "api_parameter_name", ""),
    apiName: get(block, "api_parameter_name", ""),
    title: get(block, "title", ""),
    choices: get(block, "conditions", "").map((option) => ({
      label: option.match_field_api_name,
      value: option.match_field_api_value,
      default: option.default,
    })),
  };
}

export function transformConditionalDisplayBlock(
  block: any,
  form_configs: object,
  fieldVariant: "outlined" | "standard" = "outlined",
  i18nContext: I18nContextData,
  claimData?: any
): FlexibleFormConditionalDisplayBlock {
  let requiredFieldsValidation = [];
  const ruleSet = get(block, "rule_set", []);

  const sessionStorageKey = ruleSet.length > 0 ? ruleSet[0].key : "";
  const conditionDisplayBlock = get(
    form_configs,
    "conditionDisplayBlock",
    null
  );

  if (conditionDisplayBlock && conditionDisplayBlock?.[sessionStorageKey]) {
    requiredFieldsValidation =
      conditionDisplayBlock?.[sessionStorageKey]?.condsDisplayRef || {};
  }

  if (
    conditionDisplayBlock &&
    conditionDisplayBlock?.[PageEclaim.ClaimDataById]
  ) {
    requiredFieldsValidation[PageEclaim.ClaimDataById] =
      conditionDisplayBlock?.[PageEclaim.ClaimDataById];
  }

  return {
    type: FlexibleFormBlockType.ConditionalDisplayBlock,
    sessionStorageKey: sessionStorageKey,
    claimData: claimData || [],
    ruleset: ruleSet.map((option) => {
      const { form_screen } = get(option, "form_reference[0]", {});
      const formBlocks = transformFormBlocks(
        form_screen,
        fieldVariant,
        {},
        i18nContext
      );
      return {
        key: option.key,
        value: option.value,
        requiredFieldsValidation: requiredFieldsValidation,
        formReference: formBlocks,
      };
    }),
  };
}

export function transformDocumentInfoBlock(
  block: CSFormDocumentInfoBlock,
  formConfigs: object
): FlexibleFormDocumentInfoBlock {
  return {
    type: FlexibleFormBlockType.DocumentInfoBlock,
    key: get(block, "key", ""),
    formConfigs,
    icon: "document-copy",
    title: get(block, "title", ""),
    description: get(block, "description", ""),
    content: get(block, "content", []).map((content: any) => ({
      title: get(content, "title", ""),
      data: get(content, "data", []).map((data: any) => ({
        icon: "tick",
        description: get(data, "description", ""),
      })),
    })),
  };
}

export function transformUploadDocumentBlock(
  block: CSFormUploadDocumentBlock,
  key: string,
  formConfigs: Object,
  claimType?: ClaimFormTypeEnum
): FlexibleFormUploadDocumentBlock {
  return {
    contentUid: get(block, "uid", ""),
    locale: get(block, "locale", ""),
    type: FlexibleFormBlockType.UploadDocumentBlock,
    key: key || generateUniqSerial(),
    formConfigs,
    claimType,
    variant: get(block, "variant", "full-size"),
    config: get(block, "config", ""),
    uploadData: get(block, "upload_data", []).map(
      (d: CSFormUploadDocumentBlock["upload_data"][number], index: number) => ({
        title: get(d, "title", ""),
        description: get(d, "description", ""),
        downloadContent: get(d, "download_content", []).map(
          (
            download: CSFormUploadDocumentBlock["upload_data"][number]["download_content"][number]
          ) => ({
            text: get(download, "text", ""),
            url: get(download, "url", ""),
          })
        ),
        uploadContent: {
          key:
            get(d, "upload_content.key", "") ||
            toKebabCase(get(d, "title", `upload_content_key_${index}`)),
          docTypeCode: get(d, "upload_content.doc_type_code", ""),
          text: get(d, "upload_content.text", ""),
          description: get(d, "upload_content.description", ""),
          removeIcon: replaceContentStackURL(
            get(d, "upload_content.remove_icon", "close")
          ),
          removeText: get(d, "upload_content.remove_text", ""),
          numberFileRequired: get(d, "upload_content.number_of_files", 1),
          successText: get(d, "upload_content.success_text", ""),
          errorText: get(d, "upload_content.error_text", ""),
          requiredErrorText: get(d, "upload_content.required_error_text", ""),
          exceedSizeErrorText: get(
            d,
            "upload_content.exceed_size_error_text",
            ""
          ),
          wrongFormatErrorText: get(
            d,
            "upload_content.wrong_format_error_text",
            ""
          ),
          fullCapacityText: get(d, "upload_content.full_capacity_text", ""),
          removeErrorText: get(d, "upload_content.remove_error_text", ""),
          gaLabel: get(d, "upload_content.ga_label", ""),
        },
      })
    ),
    acceptFileFormat: get(block, "accept_file_format", []).map(
      (a: CSFormUploadDocumentBlock["accept_file_format"][number]) => ({
        extension: get(a, "extension", ""),
        thumbnailImage: {
          url: replaceContentStackURL(get(a, "thumbnail_image.url", "")),
        },
        maxSize: get(a, "limit_size", 5),
      })
    ),
    uploadOption: get(block, "upload_option", "upload-each"),
    uploadDocumentApi: uploadDocument,
    removeDocumentApi: removeDocument,
  };
}

export function transformUploadNextButtonBlock(
  block: CSFormUploadNextButton,
  formConfigs: object,
  i18nContext: I18nContextData
): FlexibleFormUploadNextButton {
  return {
    type: FlexibleFormBlockType.UploadNextButton,
    formConfigs,
    title: get(block, "title", ""),
    keyName: block.key_name || "uploadNextButton",
    nextUrl: {
      title: get(block, "next_url.title", ""),
      url: resolveAbsoluteUrl(get(block, "next_url.href", ""), i18nContext),
    },
    fallbackUrl: {
      title: get(block, "fallback_url.title", ""),
      url: get(block, "fallback_url.href", ""),
    },
  };
}

function transformFormSubmitBlock(
  block: CmsFormSubmitBlock,
  i18nContext: I18nContextData,
  api?: NextGenFlexibleFormApi,
  formConfigs?: object
) {
  const failureDialog = translatePopupProps(block.failure_dialog[0]);
  const successAction = block.success_action?.[0]
    ? (() => {
        const [type, actionObject] = Object.entries(block.success_action[0])[0];
        return {
          type,
          url: resolveAbsoluteUrl(actionObject.url, i18nContext),
        };
      })()
    : undefined;
  let count = 0;

  return {
    type: FlexibleFormBlockType.SubmitBlock,
    onFailure: (error) => {
      console.log("Submit failed: ", { error });

      failureDialog &&
        showPopup({
          ...failureDialog,
          buttonsHandler: {
            primary: (popup) => {
              dismissPopup(popup.popupId);
            },
          },
        });
    },
    onSuccess: () => {
      setTimeout(() => {
        const sessionStorage = getWindow()?.sessionStorage;
        if (sessionStorage) {
          const claimId = sessionStorage.getItem(PageEclaim.ClaimIdKey) || "{}";
          const universalFormInput =
            sessionStorage.getItem(PageEclaim.UniversalFormInput) || "{}";
          const universalFormData = JSON.parse(universalFormInput);
          sessionStorage.setItem(
            "form_submitted",
            JSON.stringify({
              claimId,
              emailAddress: universalFormData.emailAddress,
            })
          );
          sessionStorage.removeItem(PageEclaim.UniversalFormInput);
          sessionStorage.removeItem(PageEclaim.ClaimDataById);
          sessionStorage.removeItem(PageEclaim.ClaimIdKey);
          sessionStorage.removeItem(PageEclaim.ClaimPolicyNumberKey);
        }
        successAction?.url && (window.location.href = successAction.url);
      }, 500);
    },
    onSubmit: async () => {
      if (!api) return;
      let apiT = { ...api };

      const sessionStorage = getWindow()?.sessionStorage;
      const baseUrlConfig = !apiT.apiUrl ? {} : { baseURL: apiT.apiUrl };
      const endpoint = apiT.apiUrl
        ? apiT.apiSubmissionEndpoint
        : getRequestUrl(apiT.apiSubmissionEndpoint);

      const formData = sessionStorage
        ? JSON.parse(
            sessionStorage.getItem(PageEclaim.UniversalFormInput) ?? "{}"
          )
        : {};

      for (const key of Object.keys(formData)) {
        if (formData[key].value) {
          formData[key] = formData[key].value;
        }
      }

      if (formConfigs) {
        if (formData["documents"]) {
          Object.keys(formData["documents"]).forEach((fieldKey) => {
            if (!checkFieldShouldSubmit(fieldKey, formConfigs, true)) {
              delete formData["documents"][fieldKey];
            }
          });
        }

        Object.keys(formData).forEach((fieldKey) => {
          if (fieldKey.includes("documents")) return;
          if (!checkFieldShouldSubmit(fieldKey, formConfigs, false)) {
            delete formData[fieldKey];
            return;
          }

          // replace values when edited
          const { value, shouldReplace } = checkShouldReplaceValue(
            fieldKey,
            formConfigs,
            formData
          );
          if (shouldReplace) {
            formData[fieldKey] = value;
          }
        });
      }

      const response = await axios({
        method: "POST",
        ...baseUrlConfig,
        url: endpoint,
        data: sessionStorage
          ? {
              ...formData,
              ...(() => {
                try {
                  return {
                    claimId:
                      sessionStorage.getItem(PageEclaim.ClaimIdKey) || "0",
                    policyNumber:
                      sessionStorage.getItem(PageEclaim.ClaimPolicyNumberKey) ||
                      formData[PageEclaim.FormInputPolicyNumber] ||
                      "0",
                    submissionChannel:
                      formData[PageEclaim.FormInputSubmissionChannel] || "Cube",
                  };
                } catch (error) {
                  console.error("FormSubmitBlock: ", error);
                  return { claimId: "0", policyNumber: "0" };
                }
              })(),
            }
          : {},
      });
      if (response.status === 200) {
        console.log({
          claimFormSubmit: "OK",
        });
      } else {
        console.log({
          claimFormSubmit: "ERROR",
          response,
        });
      }
    },
    button: {
      label: block.button?.label || "",
      icon: block.button?.icon || "",
      url: "",
    },
    consentItems: block.consent_items.map((i) => ({
      id: i._metadata.uid,
      label: resolveAbsoluteUrlInParagraph(i.label, i18nContext),
      required: i.required,
      errorMessage: i.error_message || "This is a required field",
    })),
  } as FlexibleFormSubmitBlock;
}

export function transformSummaryBlock(
  block: CSSummaryBlock["reference"][number],
  i18nContext: I18nContextData,
  api?: NextGenFlexibleFormApi,
  formConfigs?: object
): FlexibleFormSummaryBlock | FlexibleFormSubmitBlock {
  if ((block as CmsFormSubmitBlock).consent_items) {
    return {
      ...transformFormSubmitBlock(
        block as CmsFormSubmitBlock,
        i18nContext,
        api,
        formConfigs
      ),
    };
  }
  return {
    type: FlexibleFormBlockType.SummaryBlock,
    ...transformFormSummaryBlock(block as CmsFormSummary, i18nContext),
  };
}
