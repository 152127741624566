import { PublicConfigurations } from "@types";
import { replaceContentStackURL } from "@utils";
import { formatImageToPicture } from "utils/format";
import {
  ClaimFormTypeEnum,
  CSFlexibleFailResultPage,
  CSFlexibleForm,
  CSFlexibleFormBlocks,
  CSFlexibleFormSettings,
  CSFlexibleOTPVerificationScreen,
  CSFlexibleSuccessResultPage,
  CSFormBlock,
  CSFormPageBlockType,
  CSFormPageFieldType,
} from "../types/contentstack.types";
import {
  FlexibleFormBlocks,
  FlexibleFormBlockType,
  NextGenFlexibleFormApi,
  NextGenFlexibleFormOTPVerificationScreen,
  NextGenFlexibleFormParams,
  NextGenFlexibleFormProps,
  NextGenFlexibleFormResultPage,
} from "../types/flexibleform.types";
import { handleEncryptWithKey, isFormField } from "../utils";
import {
  transformButtonBlock,
  transformIntroductionBlock,
  transformSectionTitleBlock,
  transformTextBlock,
  transformUSPBlock,
  transformConditionBlock,
  transformConditionalDisplayBlock,
  transformDocumentInfoBlock,
  transformUploadDocumentBlock,
  transformUploadNextButtonBlock,
  transformSummaryBlock,
} from "./transformBlock";
import { resetApiNameSet, transformFormFields } from "./transformFields";
import get from "lodash/get";
import { I18nContextData } from "i18n/context/LanguageContext";
import { ApiRequestHeaderConfigs } from "../types/csTypes";

interface ExternActions {
  onFormSubmit?: (formObject: any, api: any) => void;
  onFormFail?: () => void;
  onFormSuccess?: () => void;
  onFormClose?: () => void;
  onVerifyOTP?: () => void;
  customApiSubmitForm?: (formObject: any, api: any) => any;
  onCallbackWithoutProceedAPI?: (formObject: any, api: any) => any;
  validateOnSubmit?: boolean;
  validateOnBlur?: boolean;
  strictValidate?: boolean;
}

export function transformCSFlexibleForm(
  flexibleFormProps: CSFlexibleForm,
  externActions: ExternActions = {},
  publicConfigurations: PublicConfigurations,
  fieldVariant: "outlined" | "standard" = "outlined",
  i18nContext: I18nContextData,
  claimData: any
): NextGenFlexibleFormProps {
  const {
    form_screen,
    error_screen,
    thank_you_screen,
    form_settings,
    otp_verification_screen,
    display_setting,
  } = flexibleFormProps;

  resetApiNameSet();

  const api = {
    ...transformFormApi(form_settings, publicConfigurations),
    customApiSubmitForm: externActions?.customApiSubmitForm,
  };
  const formBlocks = transformFormBlocks(
    form_screen,
    fieldVariant,
    form_settings.form_configs || {},
    i18nContext,
    api,
    claimData,
    form_settings?.claim_form_section?.type
  );
  const submitButton = formBlocks.find(
    (fb) => fb.type === FlexibleFormBlockType.ButtonBlock
  );
  const isCallbackWithoutProceedAPI = get(submitButton, "url", "");

  return {
    pages: {
      form: formBlocks,
      success: transformSuccessResultPage(thank_you_screen),
      failure: transformFailResultPage(error_screen),
      otp: otp_verification_screen?.enabled
        ? transformOTPVerificationScreen(otp_verification_screen)
        : null,
    },
    api,
    style: transformStyle(form_settings),
    params: {
      ...transformFormParams(form_settings, externActions),
      displaySettings: display_setting,
    },
    isCallbackWithoutProceedAPI: !!isCallbackWithoutProceedAPI,
  };
}

function transformStyle(settings: CSFlexibleFormSettings) {
  const {
    background_color,
    background_desktop,
    background_mobile,
    full_width,
    hide_header,
  } = settings;

  const hasBackground = !!(background_desktop || background_mobile);

  return {
    backgroundColor: background_color || "#FFF",
    backgroundImage: hasBackground
      ? formatImageToPicture(
          background_desktop?.url ?? "",
          background_mobile?.url
        ).media
      : null,
    fullWidth: full_width ?? false,
    hideHeader: hide_header ?? false,
  };
}

function transformFormApi(
  settings: CSFlexibleFormSettings,
  publicConfigurations: PublicConfigurations
): NextGenFlexibleFormApi {
  const {
    api_request_body: apiRequestBody,
    api_request_header: apiRequestHeader,
    api_url: apiUrl,
    submission_endpoint: apiSubmissionEndpoint,
    api_error_message: apiErrorMessage,
    api_request_header_configs: apiRequestHeaderConfigs,
  } = settings;

  const isContentStack =
    apiRequestHeaderConfigs === ApiRequestHeaderConfigs.ContentStack;

  return {
    apiSubmissionEndpoint: apiSubmissionEndpoint || "",
    apiUrl: isContentStack
      ? apiUrl
      : publicConfigurations?.flexibleFormApiUrl ?? "",
    apiRequestHeader: isContentStack
      ? { ...apiRequestHeader }
      : {
          ...apiRequestHeader,
          "x-api-key": publicConfigurations?.flexibleFormApiKey ?? "",
        },
    apiRequestBody,
    apiErrorMessage:
      apiErrorMessage ||
      "Sorry, our systems are experiencing some difficulties. Please submit again shortly.",
  };
}

function transformFormParams(
  settings: CSFlexibleFormSettings,
  externActions: ExternActions
): NextGenFlexibleFormParams {
  return {
    isPopup: settings.display_close_icon ?? false,
    companyLogo: formatImageToPicture(settings.company_logo?.url ?? "").media,

    onClosePopup: externActions.onFormClose,
    onFormSubmit: externActions.onFormSubmit,
    onFormFail: externActions.onFormFail,
    onFormSuccess: externActions.onFormSuccess,
    onVerifyOTP: externActions.onVerifyOTP,
    onEncryptWithKey: handleEncryptWithKey,
    onCallbackWithoutProceedAPI: externActions.onCallbackWithoutProceedAPI,
    validateOnSubmit: externActions.validateOnSubmit,
    validateOnBlur: externActions.validateOnBlur,
    strictValidate: externActions.strictValidate,
  };
}

function transformSuccessResultPage(
  pageParams: CSFlexibleSuccessResultPage
): NextGenFlexibleFormResultPage {
  const {
    title,
    description,
    image,
    background_desktop,
    background_mobile,
    background_color,
  } = pageParams;
  const hasBackground = !!(background_desktop || background_mobile);

  return {
    title,
    description,
    picture: formatImageToPicture(replaceContentStackURL(image?.url) ?? "")
      .media,
    backgroundColor: background_color ?? "#FFF",
    backgroundImage: hasBackground
      ? formatImageToPicture(
          background_desktop?.url ?? "",
          background_mobile?.url
        ).media
      : undefined,
  };
}

function transformFailResultPage(
  pageParams: CSFlexibleFailResultPage
): NextGenFlexibleFormResultPage | null {
  const {
    title,
    description,
    image,
    background_desktop,
    background_mobile,
    background_color,
    button_label,
    button_link,
  } = pageParams;

  // If there is no title and description, we don't need to show the page
  // An error message will be shown on the form page
  if (!title && !description) {
    return null;
  }
  const hasBackground = !!(background_desktop || background_mobile);

  return {
    title,
    description,
    picture: formatImageToPicture(image?.url ?? "").media,
    backgroundColor: background_color ?? "#FFF",
    backgroundImage: hasBackground
      ? formatImageToPicture(
          background_desktop?.url ?? "",
          background_mobile?.url
        ).media
      : undefined,
    buttonLabel: button_label,
    buttonLink: button_link,
  };
}

function transformOTPVerificationScreen(
  pageParams: CSFlexibleOTPVerificationScreen
): NextGenFlexibleFormOTPVerificationScreen | null {
  const {
    title,
    description,
    subtitle,
    enabled,
    length_of_otp,
    resend_text,
    resend_button,
    success_screen,
  } = pageParams;

  if (!enabled) {
    return null;
  }

  return {
    title,
    description,
    subtitle,
    enabled,
    lengthOfOTP: length_of_otp,
    resendText: resend_text,
    resendButton: resend_button,
    successScreen: {
      image: {
        url: success_screen?.image?.url || "",
      },
      title: success_screen?.title || "",
      description: success_screen?.description || "",
      button: {
        gaLabel: success_screen?.button?.ga_label || "",
        link: {
          href: success_screen?.button?.link.href || "",
          title: success_screen?.button?.link.title || "",
        },
      },
    },
  };
}

export function transformFormBlocks(
  blocks: Partial<CSFlexibleFormBlocks>[],
  fieldVariant: "outlined" | "standard" = "outlined",
  form_configs: object,
  i18nContext: I18nContextData,
  api?: NextGenFlexibleFormApi,
  claimData?: any,
  claimType?: ClaimFormTypeEnum
) {
  const blocksArray: CSFormBlock[] = [];

  // Extract block type from the object
  if (blocks && blocks.length > 0) {
    blocks.forEach((block, i) => {
      const key = Object.keys(block)[0];

      if (key && block[key]) {
        blocksArray.push({
          ...block[key],
          type: key,
        });
      }
    });
  }

  const compBlocks: FlexibleFormBlocks[] = [];

  for (let i = 0; i < blocksArray.length; i++) {
    const blockContent = blocksArray[i];
    let tempBlock: null | FlexibleFormBlocks = null;

    if (!blockContent.type) continue;

    // Handle blocks
    if (blockContent.type === CSFormPageBlockType.IntroductionBlock) {
      tempBlock = transformIntroductionBlock(blockContent);
    }

    if (blockContent.type === CSFormPageBlockType.MarketingBlock) {
      tempBlock = transformUSPBlock(blockContent);
    }

    if (blockContent.type === CSFormPageBlockType.SectionTitle) {
      tempBlock = transformSectionTitleBlock(blockContent);
    }

    if (blockContent.type === CSFormPageBlockType.ButtonBlock) {
      tempBlock = transformButtonBlock(blockContent);
    }

    if (blockContent.type === CSFormPageBlockType.FreeText) {
      tempBlock = transformTextBlock(blockContent);
    }

    if (blockContent.type === CSFormPageBlockType.ConditionalDisplayBlock) {
      tempBlock = transformConditionalDisplayBlock(
        blockContent,
        form_configs,
        fieldVariant,
        i18nContext,
        claimData
      );
    }

    if (blockContent.type === CSFormPageBlockType.DocumentInfoBlock) {
      tempBlock = transformDocumentInfoBlock(blockContent, form_configs);
    }

    if (blockContent.type === CSFormPageBlockType.UploadDocumentBlock) {
      tempBlock = transformUploadDocumentBlock(
        get(blockContent, "reference.[0]", []),
        get(blockContent, "key", ""),
        form_configs,
        claimType
      );
    }

    if (blockContent.type === CSFormPageBlockType.UploadNextButton) {
      tempBlock = transformUploadNextButtonBlock(
        blockContent,
        form_configs,
        i18nContext
      );
    }

    if (blockContent.type === CSFormPageBlockType.SummaryBlock) {
      tempBlock = transformSummaryBlock(
        get(blockContent, "reference.[0]", []),
        i18nContext,
        api,
        form_configs
      );
    }

    // Handle fields
    if (isFormField(blockContent.type)) {
      const fields = [blockContent];
      for (let j = i + 1; j < blocksArray.length; j++) {
        const blockContent = blocksArray[j];
        if (!isFormField(blockContent.type)) {
          break;
        }
        fields.push(blockContent);
        i++;
      }

      tempBlock = transformFormFields(fields, fieldVariant, claimData);
    }

    if (tempBlock) compBlocks.push(tempBlock);
  }

  return compBlocks;
}
