import isArray from "lodash/isArray";
import values from "lodash/values";
import omit from "lodash/omit";
const splitName = (fullName?: string) => {
  if (!fullName) {
    return {};
  }

  const names = fullName.split(" ");

  let lastName: string = "";
  const firstName = names[0];

  if (names.length > 1) {
    lastName = names.slice(1).join(" ");
  }

  return {
    firstName,
    lastName,
  };
};

export const buildRequestBody = ({
  formData,
  i18nContext,
  emailTemplates,
  services,
  csBranchName,
  isGeneralForm,
  productMetadata = {} as any,
  defaultProductName
}) => {
  const language = i18nContext.language;

  const processedFormData: any = {
    ...omit(formData, ["productName"]),
    ...splitName(formData.name),
    preferredContactMethod: "phone",
  };

  if (!isGeneralForm) {
    if (productMetadata.productName) {
      processedFormData.productName = productMetadata.productName;
    } else if (formData.productName) {
      processedFormData.productName = formData.productName;
    } else {
      // trying to get from h1 tag
      const defaultProductName = i18nContext.componentConfigs?.gaProductMetadata?.item_name || document?.querySelector("h1")?.textContent;

      if (defaultProductName) {
        processedFormData.productName = defaultProductName;
      }
    }

    if (productMetadata.productChannel) {
      processedFormData.channel = productMetadata?.productChannel;
    }
  }

  const isFIB = values(formData).some((value) => {
    if (isArray(value)) {
      return value.some((x) => x.includes("insurance"));
    }
    return false;
  });

  const type = isFIB ? { type: "my-fib" } : {};
  
  if (isFIB) {
    if (formData.productNameCustomized && formData.productNameCustomized.length > 0) {
      processedFormData.productName = formData.productNameCustomized[0];
    } else if (defaultProductName) {
      processedFormData.productName = defaultProductName;
    } 
    if (formData.productChannelCustomized && formData.productChannelCustomized.length > 0) {
      processedFormData.channel = formData.productChannelCustomized[0];
    }
    if (formData.homeProvinceCustomized && formData.homeProvinceCustomized.length > 0 && !processedFormData.homeProvince) {
      processedFormData.homeProvince = formData.homeProvinceCustomized[0];
    }
    if (formData.ageCustomized && formData.ageCustomized.length > 0 && !processedFormData.age) {
      processedFormData.age = formData.ageCustomized[0];
    }
    delete processedFormData.productType;
    delete processedFormData.productNameCustomized;
    delete processedFormData.productChannelCustomized;
    delete processedFormData.homeProvinceCustomized;
    delete processedFormData.ageCustomized;
  }

  return {
    user: {
      profile: {
        contact: {
          email: {
            parameters: processedFormData,
          },
        },
      },
      lead: processedFormData,
    },
    language: language.languageCode,
    locale: language.locale,
    country: i18nContext.countryCode.split("-")[0],
    emailTemplates: Object.fromEntries(
      emailTemplates?.map((e) => [e.template, e.uid]) || []
    ),
    services: services,
    ...type,
    csBranch: csBranchName,
  };
};
