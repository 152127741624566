import { replaceContentStackURL } from "@utils";
import I18nContext, { I18nContextData } from "i18n/context/LanguageContext";
import { resolveAbsoluteUrl, resolveAbsoluteUrlServer } from "utils/route";
import {
  CmsFormSummary,
  CmsFormSummaryBlock,
  CmsFormSummaryDivider,
  CmsFormSummaryFileFieldBlock,
  CmsFormSummaryMultiColumnBlock,
  CmsFormSummarySection,
  CmsFormSummarySmallTextBlock,
  CmsFormSummaryTextField,
  FormSummaryBlock,
  FormSummaryDesignVariant,
  FormSummaryFileField,
  FormSummaryLayoutDivider,
  FormSummaryMultiColumnLayout,
  FormSummaryProps,
  FormSummarySection,
  FormSummarySmallTextField,
  FormSummaryTextField,
} from "../types/formSummary.type";

export function transformFormSummaryBlock(
  cmsData: CmsFormSummary,
  i18nContext: I18nContextData
): FormSummaryProps {
  if (!cmsData) {
    return {
      blocks: [],
      title: {
        description: "",
        title: "",
      },
      sectionKey: "",
      designVariant: FormSummaryDesignVariant.Variant1,
      formConfig: {},
    };
  }
  const blocks = cmsData.block[0] || [];
  return {
    blocks: blocks.blocks.map((block) =>
      transformCmsFormSummaryBlock(block, i18nContext)
    ),
    title: cmsData.display_title,
    sectionKey: cmsData.section_key || "",
    sectionUrl: resolveAbsoluteUrl(cmsData.section_key || "", i18nContext),
    designVariant: cmsData.design_variant,
    formConfig: cmsData.form_config,
  };
}

type KeysOf<T> = T extends T ? keyof T : never;
const getKeys = Object.keys as <T extends object>(obj: T) => Array<KeysOf<T>>;

const BLOCK_TRANSFORMERS: Record<KeysOf<CmsFormSummaryBlock>, Function> = {
  section: transformSectionBlock,
  small_text: transformSmallTextBlock,
  file_upload_field: transformFileUploadFieldBlock,
  multi_column_layout: transformMultiColumnBlock,
  divider: (props: CmsFormSummaryDivider) =>
  ({
    divider: {
      color: props.divider.color,
      keyName: props.divider.key || "",
    },
  } as FormSummaryLayoutDivider),
  text_field: transformTextField,
};

function transformCmsFormSummaryBlock(
  cmsData: CmsFormSummaryBlock,
  i18nContext: I18nContextData
): FormSummaryBlock {
  const keyName = getKeys(cmsData)?.[0] || "";
  const transform = BLOCK_TRANSFORMERS[keyName];
  return transform ? transform(cmsData, i18nContext) : {};
}

function transformSectionBlock(
  cmsData: CmsFormSummarySection,
  i18nContext: I18nContextData
): FormSummarySection | null {
  if (!cmsData.section) {
    return null;
  }

  const blocks = cmsData.section.blocks[0] || [];
  return {
    section: {
      title: cmsData.section.title,
      sectionKey: cmsData.section.form_step_key,
      sectionUrl: resolveAbsoluteUrl(
        cmsData.section.form_step_key || "",
        i18nContext
      ),
      blocks: blocks?.blocks?.map((block) =>
        transformCmsFormSummaryBlock(block, i18nContext)
      ),
    },
  };
}

function transformSmallTextBlock(
  cmsData: CmsFormSummarySmallTextBlock
): FormSummarySmallTextField | null {
  if (!cmsData.small_text) {
    return null;
  }

  return {
    smallText: {
      text: cmsData.small_text.text,
      columnSpan: cmsData.small_text.column_span || 1,
      keyName: cmsData.small_text.field_key,
    },
  };
}

function transformTextField(
  cmsData: CmsFormSummaryTextField
): FormSummaryTextField | null {
  if (!cmsData.text_field) {
    return null;
  }

  return {
    textField: {
      keyName: cmsData.text_field.key_name,
      size: cmsData.text_field.size,
      bold: cmsData.text_field.bold,
    },
  };
}

function transformMultiColumnBlock(
  cmsData: CmsFormSummaryMultiColumnBlock,
  i18nContext: I18nContextData
): FormSummaryMultiColumnLayout | null {
  if (!(cmsData as CmsFormSummaryMultiColumnBlock).multi_column_layout) {
    return null;
  }

  return {
    multiColumnLayout: {
      gap: {
        mobile: cmsData.multi_column_layout.gap?.mobile || 16,
        desktop: cmsData.multi_column_layout.gap?.desktop || 16,
      },
      numberOfColumns: cmsData.multi_column_layout.number_of_columns,
      crossColumnRowAligned: true,
      disableRowSpanOnMobile:
        cmsData.multi_column_layout.disable_row_span_on_mobile,
      columns: cmsData.multi_column_layout.columns.map((column) => {
        const blocks = column.blocks || [];
        return {
          size: "",
          blocks: blocks.map((block) =>
            transformCmsFormSummaryBlock(block, i18nContext)
          ),
        };
      }),
    },
  };
}

function transformFileUploadFieldBlock(
  cmsData: CmsFormSummaryFileFieldBlock
): FormSummaryFileField | null {
  if (!cmsData.file_upload_field) {
    return null;
  }

  return {
    fileUploadField: {
      files: cmsData.file_upload_field.files.map((file) => ({
        icon: replaceContentStackURL(file.file_icon.url),
        keyName: file.key_name,
        label: file.label,
        displayFormat: file.display_format,
      })),
    },
  };
}
