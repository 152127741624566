// Nextgen Lib Props
import {
  RemoveDocumentInfo,
  RequestDocumentInfo,
} from "components/UploadDocument/services/uploadDocument.service";
import {
  FlexibleFormApiFormattingType,
  FlexibleFormFormattingType,
  FlexibleFormValidationType,
} from "./common.type";
import { NextGenPicture } from "./nextGen.types";
import { FormSummaryProps } from "./formSummary.type";
import { ClaimFormTypeEnum } from "./contentstack.types";

export interface NextGenFlexibleFormProps {
  pages: {
    form: FlexibleFormBlocks[];
    otp: NextGenFlexibleFormOTPVerificationScreen | null;
    success: NextGenFlexibleFormResultPage;
    failure: NextGenFlexibleFormResultPage | null;
  };
  style: NextGenFlexibleFormStyle;
  params: NextGenFlexibleFormParams;
  api: NextGenFlexibleFormApi;
  isCallbackWithoutProceedAPI: boolean;
}

export interface NextGenFlexibleFormResultPage {
  title: string;
  description: string;
  picture: NextGenPicture;
  backgroundColor: string;
  backgroundImage?: NextGenPicture;
  buttonLabel?: string;
  buttonLink?: string;
}

interface NextGenFlexibleFormStyle {
  backgroundColor: string;
  backgroundImage: null | NextGenPicture;
  fullWidth: boolean;
  hideHeader: boolean;
}

export interface NextGenFlexibleFormOTPVerificationScreen {
  title: string;
  description: string;
  subtitle: string;
  enabled: boolean;
  lengthOfOTP: number;
  resendText: string;
  resendButton: string;
  successScreen: {
    image: {
      url: string;
    };
    title: string;
    description: string;
    button: {
      gaLabel: string;
      link: {
        href: string;
        title: string;
      };
    };
  };
}

export interface NextGenFlexibleFormParams {
  isPopup: boolean;
  onClosePopup?: () => void;
  onFormSubmit?: (formObject: any, requestHeader: any) => void;
  onFormFail?: () => void;
  onFormSuccess?: () => void;
  onVerifyOTP?: () => void;
  onEncryptWithKey?: (plaintext: string, key: string) => void;
  onCallbackWithoutProceedAPI?: (formObject: any, api: any) => void;
  companyLogo?: NextGenPicture;
  validateOnSubmit?: boolean;
  validateOnBlur?: boolean;
  strictValidate?: boolean;
  displaySettings?: string;
}

export interface NextGenFlexibleFormApi {
  apiSubmissionEndpoint: string;
  apiUrl: string;
  apiRequestHeader: Object;
  apiRequestBody: Object;
  apiErrorMessage: string;
  customApiSubmitForm?: (formObject: any, api: any) => any;
}

export enum FlexibleFormBlockType {
  IntroductionBlock = "header_block",
  USPBlock = "usp_block",
  SectionTitle = "form_title_block",
  ButtonBlock = "submit_button",
  FreeTextBlock = "free_text_block",
  ConditionalBlock = "conditional_block",
  ConditionalDisplayBlock = "display_block",
  FormFields = "form_fields",
  DocumentInfoBlock = "document_info_block",
  UploadDocumentBlock = "upload_document_block",
  UploadNextButton = "upload_next_button",
  SummaryBlock = "summarise",
  SubmitBlock = "submit_block",
}

export type FlexibleFormBlocks =
  | FlexibleFormIntroductionBlock
  | FlexibleFormUSPBlock
  | FlexibleFormSectionTitleBlock
  | FlexibleFButtomBlock
  | FlexibleFormFieldsBlock
  | FlexibleFormConditionalDisplayBlock
  | FlexibleFormFreeTextBlock
  | FlexibleFormDocumentInfoBlock
  | FlexibleFormUploadDocumentBlock
  | FlexibleFormUploadNextButton
  | FlexibleFormSubmitBlock
  | FlexibleFormSummaryBlock;

export interface FlexibleFormIntroductionBlock {
  type: FlexibleFormBlockType.IntroductionBlock;
  title: string;
  picture: null | NextGenPicture;
  style: Object;
}

export interface FlexibleFormUSPBlock {
  type: FlexibleFormBlockType.USPBlock;
  title: string;
  subtitle: string;
  image: null | NextGenPicture;
  items: {
    picture: null | NextGenPicture;
    title: string;
    text: string;
  }[];
  style: Object;
}

export interface FlexibleFormSectionTitleBlock {
  type: FlexibleFormBlockType.SectionTitle;
  picture: null | NextGenPicture;
  title: string;
  style: Object;
}

export interface FlexibleFButtomBlock {
  type: FlexibleFormBlockType.ButtonBlock;
  title: string;
  style: Object;
  icon: string;
  url: string;
}

export interface FlexibleFormConditionalBlock {
  type: FlexibleFormBlockType.ConditionalBlock;
  sessionStorageKey: string;
  title: string;
  apiName: string;
  choices: {
    value: string;
    label: string;
  }[];
}

export interface FlexibleFormConditionalDisplayBlock {
  type: FlexibleFormBlockType.ConditionalDisplayBlock;
  sessionStorageKey: string;
  claimData?: any[];
  ruleset: {
    key: string;
    value: string;
    formReference: any;
  }[];
}

export interface FlexibleFormDocumentInfoBlock {
  type: FlexibleFormBlockType.DocumentInfoBlock;
  key: string;
  formConfigs: Object;
  icon: string;
  title: string;
  description: string;
  content: {
    data: {
      icon: string;
      description: string;
    }[];
  }[];
}

export enum FieldType {
  TEXT = "text",
  SELECT = "select",
  PASSWORD = "password",
  CHECKBOX = "checkbox",
  CONDITIONAL_CHECKBOX = "conditional_checkbox",
  EMAIL = "email",
  CONTACT_NUMBER = "contact_number",
  LIST = "list",
  DATEPICKER = "datepicker",
  RADIO = "radio",
  CONDITIONAL_BUTTON_FIELD = "conditional_button",
}

export interface FlexibleFormFieldsBlock {
  type: FlexibleFormBlockType.FormFields;
  fields: FlexibleFormFields[];
  style: {};
}

export interface FlexibleFormFreeTextBlock {
  type: FlexibleFormBlockType.FreeTextBlock;
  text: string;
  style: Object;
}

export interface FlexibleFormSummaryBlock extends FormSummaryProps {
  type: FlexibleFormBlockType.SummaryBlock;
}

export interface FlexibleFormSubmitBlock {
  type: FlexibleFormBlockType.SubmitBlock;
  consentItems: {
    id: string;
    label: string;
    required: boolean;
    errorMessage: string;
  }[];
  onSuccess: Function;
  button: {
    label: string;
    icon: string;
  };
  onFailure: Function;
  onSubmit: Function;
}

export interface FlexibleFormUploadDocumentBlock {
  contentUid: string;
  locale: string;
  type: FlexibleFormBlockType.UploadDocumentBlock;
  key: string;
  formConfigs: Object;
  claimType?: ClaimFormTypeEnum;
  variant: "narrow" | "full-size";
  config: string;
  uploadData: {
    title: string;
    description: string;
    downloadContent: {
      text: string;
      url: string;
    }[];
    uploadContent: {
      key: string;
      text: string;
      docTypeCode: string;
      description: string;
      removeIcon: string;
      removeText: string;
      numberFileRequired: number;
      successText: string;
      errorText: string;
      requiredErrorText: string;
      wrongFormatErrorText: string;
      exceedSizeErrorText: string;
      fullCapacityText: string;
      removeErrorText: string;
      gaLabel: string;
    };
  }[];
  acceptFileFormat: {
    extension: string;
    thumbnailImage: {
      url: string;
    };
    maxSize: number;
  }[];
  uploadOption: "upload-each" | "upload-all";
  uploadDocumentApi: (
    data: RequestDocumentInfo,
    claimType: ClaimFormTypeEnum
  ) => any;
  removeDocumentApi: (
    data: RemoveDocumentInfo,
    claimType: ClaimFormTypeEnum
  ) => any;
}

export interface FlexibleFormUploadNextButton {
  type: FlexibleFormBlockType.UploadNextButton;
  title: string;
  formConfigs: object;
  keyName: string;
  nextUrl: {
    title: string;
    url: string;
  };
  fallbackUrl: {
    title: string;
    url: string;
  };
}

export type FlexibleFormFields = (
  | FlexibleFormTextField
  | FlexibleFormSelectField
  | FlexibleFormPasswordField
  | FlexibleFormCheckboxField
  | FlexibleFormEmailField
  | FlexibleFormContactNumberField
  | FlexibleFormListField
  | FlexibleFormDatePickerField
  | FlexibleFormConditionalButtonField
) & {
  fieldVariant: "outlined" | "standard";
};

export interface FlexibleFormTextField {
  fieldType: FieldType.TEXT;
  fieldName: string;
  fullWidth: boolean;
  isRequired: boolean;
  isVisible: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  isEncrypted: boolean;
  defaultValue: string;
  placeholder: string;
  apiName: string;
  validationScript: () => void;
  validationOptions?: FlexibleFormFieldValidationOption[];
  formattingOptions: FlexibleFormFieldFormattingOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  includeInApi: boolean;
  fieldClass: string;
}

export interface FlexibleFormSelectField {
  fieldType: FieldType.SELECT;
  fieldName: string;
  fullWidth: boolean;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  isEncrypted: boolean;
  placeholder: string;
  defaultValue:
    | {
        text: string;
        value: string;
      }
    | {};
  apiName: string;
  availableValues: {
    text: string;
    value: string;
  }[];
  validationOptions?: FlexibleFormFieldValidationOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  includeInApi: boolean;
}

export interface FlexibleFormPasswordField {
  fieldType: FieldType.PASSWORD;
  fieldName: string;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  placeholder: string;
  defaultValue: string;
  apiName: string;
  validationOptions?: FlexibleFormFieldValidationOption[];
  formattingOptions: FlexibleFormFieldFormattingOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];

  mustContainsLowerChars: boolean;
  mustContainsNumbers: boolean;
  mustContainsSpecialChars: boolean;
  mustContainsUpperChars: boolean;
  mustMatch: [];
  minLength: number;
  maxLength: number;
  includeInApi: boolean;
}

export interface FlexibleFormCheckboxField {
  fieldType: FieldType.CHECKBOX;
  apiName: string;
  defaultValue: boolean;
  fieldName: string;
  isRequired: boolean;
  isVisible: boolean;
  isPII: boolean;
  text: string;
  validationOptions?: FlexibleFormFieldValidationOption[];
  includeInApi: boolean;
}

export interface FlexibleFormConditionalCheckboxField {
  fieldType: FieldType.CONDITIONAL_CHECKBOX;
  apiName: string;
  defaultValue: boolean;
  fieldName: string;
  isRequired: boolean;
  isVisible: boolean;
  isPII: boolean;
  conditionalTexts: {
    fieldApiName: string;
    fieldApiValue: string;
    text: string;
    isDefault: boolean;
  }[];
  validationOptions?: FlexibleFormFieldValidationOption[];
  includeInApi: boolean;
}

export interface FlexibleFormEmailField {
  fieldType: FieldType.EMAIL;
  fieldName: string;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  defaultValue: string;
  apiName: string;
  placeholder: string;
  validationOptions?: FlexibleFormFieldValidationOption[];
  formattingOptions: FlexibleFormFieldFormattingOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  includeInApi: boolean;
}

export interface FlexibleFormContactNumberField {
  fieldType: FieldType.CONTACT_NUMBER;
  fieldName: string;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  isEncrypted: boolean;
  defaultValue: string;
  apiName: string;
  country?: string;
  placeholder: string;
  validationOptions?: FlexibleFormFieldValidationOption[];
  formattingOptions: FlexibleFormFieldFormattingOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  includeInApi: boolean;
}

export interface FlexibleFormListField {
  fieldType: FieldType.LIST;
  fieldName: string;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  placeholder: string;
  defaultValue: {
    text: string;
    value: string;
  }[];
  apiName: string;
  availableValues: {
    text: string;
    value: string;
  }[];
  validationOptions?: FlexibleFormFieldValidationOption[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  includeInApi: boolean;
  multipleChoice: boolean;
}

export interface FlexibleFormRadioField {
  fieldType: FieldType.RADIO;
  fieldName: string;
  placeholder: string;
  isVisible: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  apiName: string;
  availableValues: {
    text: string;
    value: string;
  }[];
}

export interface FlexibleFormDatePickerField {
  fieldType: FieldType.DATEPICKER;
  fieldName: string;
  isRequired: boolean;
  isVisible: boolean;
  isReadOnly: boolean;
  isPII: boolean;
  defaultValue: string;
  placeholder: string;
  apiName: string;
  validationOptions?: FlexibleFormFieldValidationOption[];
  apiFormattingOptions?: FlexibleFormFieldApiFormattingOption[];
  validationScript: () => void;
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };

  // Date picker props
  minAge?: number;
  maxAge?: number;
  minDate?: string;
  maxDate?: string;
  allowDateInFuture?: boolean;

  dateFormatInput: string;
  dateFormattingProps: Object;

  includeInApi: boolean;
}

export interface FlexibleFormConditionalButtonField {
  fieldType: FieldType.CONDITIONAL_BUTTON_FIELD;
  apiName: string;
  fieldName: string;
  isRequired: boolean;
  isVisible: boolean;
  sessionStorageKey: string;
  defaultValue: string;
  placeholder: string;
  isPII: boolean;
  fieldClass: string;
  choices: {
    value: string;
    label: string;
  }[];
  validationRules: {
    [key: string]: FlexibleFormValidationRule;
  };
  validationOptions?: FlexibleFormFieldValidationOption[];
}

interface FlexibleFormValidationRule {
  value?: string | number | Array<any> | Object;
  errorMessage?: string;
}

export interface FlexibleFormFieldValidationOption {
  type: FlexibleFormValidationType;
  params?: any; //
  errorMessage?: string;
}

export interface FlexibleFormFieldFormattingOption {
  type: FlexibleFormFormattingType;
  params?: any; //
}

export interface FlexibleFormFieldApiFormattingOption {
  type: FlexibleFormApiFormattingType;
  params?: any; //
}
