interface IEventBackbone {
  addEventListener(
    eventName: string,
    handler: EventListenerOrEventListenerObject
  ): void;

  removeEventListener(
    eventName: string,
    handler?: EventListenerOrEventListenerObject
  ): void;
  dispatchEvent(event: Event): boolean;
}

const stubBackbone: IEventBackbone = {
  addEventListener(
    _eventName: string,
    _handler: EventListenerOrEventListenerObject
  ) {},
  removeEventListener(
    _eventName: string,
    _handler?: EventListenerOrEventListenerObject
  ) {},
  dispatchEvent(_event: Event): boolean {
    return true;
  },
};

class EventBus {
  private backbone: IEventBackbone;

  constructor() {
    if (typeof window !== "undefined") {
      this.backbone = window;
    } else {
      this.backbone = stubBackbone;
    }
  }

  on(eventName: string, handler: EventListenerOrEventListenerObject) {
    this.backbone.addEventListener(eventName, handler);
  }
  off(eventName: string, handler?: EventListenerOrEventListenerObject) {
    this.backbone.removeEventListener(eventName, handler);
  }

  dispatch<T>(eventName: string, data?: T) {
    const event = data
      ? new CustomEvent(eventName, data)
      : new Event(eventName);
    this.backbone.dispatchEvent(event);
  }
}

const eventBus = new EventBus();
export default eventBus;
