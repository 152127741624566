export interface CSMedia {
    content_type: string;
    created_at: string;
    created_by: string;
    file_size: string;
    filename: string;
    title: string;
    uid: string;
    updated_at: string;
    updated_by: string;
    url: string;
}

export interface CSFlexibleOTPSuccessScreen {
    image: {
        url: string;
    };
    title: string;
    description: string;
    button: {
        ga_label: string;
        link: {
            href: string;
            title: string;
        }
    }
}


export enum ApiRequestHeaderConfigs {
    ContentStack = "content_stack",
    SpringConfigs = "spring_configs",
}
