import { breakpoint } from "@d2c-ui-components-react";
import { Grid } from "@material-ui/core";
import axios from "utils/axios";
import { CSFlexiForm } from "components/FlexiForm";
import { fwdColors } from "config/fwd-colors";
import I18nContext from "i18n/context/LanguageContext";
import { useContext } from "react";
import { BlockContainer } from "shared-components/BlockContainer";
import { Icon } from "shared-components/Icon";
import styled from "styled-components";
import { useSlugs } from "utils/route";
import { SHA256, sanitizeHtml } from "utils/string";
import { buildRequestBody } from "./utils";
import isEmpty from "lodash/isEmpty";
import { LeadFormContext } from "shared-components/LeadFormContextProvider";
import { gtmUltils } from "@utils";
import { ProductAffiliation } from "types/PageType.enum";
import AppContext from "context/AppContext";

const FormWrapper = styled.div`
  padding: 24px 16px;
  border-radius: 16px;
  color: ${fwdColors.darkGreen};
  background-color: ${fwdColors.white};

  ${breakpoint("sm")`
    padding: 40px 56px;
    border-radius: 40px;

  `}

  .flexible-form {
    min-height: 0 !important;
    display: block !important;

    > div {
      padding: 0 !important;
    }

    .form-button-error-message {
      margin-top: 32px;
    }

    &__container {
      max-width: none !important;
      padding: 0 !important;

      > section > div.MuiContainer-root {
        max-width: none;
      }
    }

    &__header {
      display: none;
    }

    &__field-container {
      grid-row-gap: 12px;
      grid-column-gap: 12px;

      .MuiFormControl-root {
        height: auto;
      }
      .form-error-message {
        margin-top: 8px !important;

        .MuiTypography-colorError {
          font-size: 14px;
          color: #b30909;
          overflow: visible;
        }
      }
    }

    &__list-field {
      .item {
        color: ${fwdColors.darkGreen};
        margin-bottom: 0;
      }

      .item.selected {
        color: ${fwdColors.white};
      }
    }

    .result-page {
      padding: 25px 80px 147px 80px;
      margin: 0 !important;
      &__title {
        font-size: 25px !important;
        font-weight: 700 !important;
        line-height: 25px;
        margin-top: 32px;
        margin-bottom: 24px !important;
      }

      &__content {
        font-size: 16px !important;
        font-weight: 450;
      }
    }

    .form-tick-box {
      margin-top: 12px;

      span.MuiFormControlLabel-label {
        border-radius: 8px;
        background: ${fwdColors.grey50};
        padding: 6px 12px;
        p {
          margin: 0;
        }

        > p.MuiTypography-root {
          font-size: 14px;

          ${breakpoint("sm")`
            font-size: 16px;
          `}
        }
      }
    }

    div.section-header {
      margin-top: 24px !important;
      margin-bottom: 4px !important;

      > .MuiTypography-subtitle1 {
        font-size: 14px;
        font-weight: 700 !important;
      }
    }
  }
`;

const StyledBlockContainer = styled(BlockContainer)`
  padding-top: 32px;
  padding-bottom: 32px;

  ${breakpoint("sm")`
  padding-top: 48px;
  padding-bottom: 48px;
`}
`;

const FormRadiusContainer = styled.div`
  border-radius: 40px;
  background-color: ${fwdColors.white};
`;

const HeaderWrapper = styled(Grid)`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 16px;

  ${breakpoint("lg")`
    padding: 40px 64px 40px 0px;
  `}
`;

const Title = styled.h2`
  font-size: 31px;
  font-weight: 700;
  line-height: 49px;
  margin: 0;

  ${breakpoint("sm")`
    font-size: 39px;
  `}
`;

const Description = styled.div`
  font-weight: 450;
  line-height: 24px;
`;
const FormHead = styled.div`
  ${Title} {
    font-size: 25px;
  }
  ${Description} {
    font-size: 16px;
  }
`;

const handlePushEvent = (formObject: any, i18nContext: any) => {
  if (isEmpty(formObject)) {
    return;
  }

  const productName =
    i18nContext.componentConfigs?.gaProductMetadata?.item_name || "";
  const productCategory =
    i18nContext.componentConfigs?.gaProductMetadata?.item_category || "";
  const productAffiliation = formObject.productName?.[0]
    ? formObject.productName?.[0]?.includes(ProductAffiliation.Insurance)
      ? ProductAffiliation.Insurance
      : ProductAffiliation.Takaful
    : i18nContext.componentConfigs?.gaPageData?.product_affiliation;
  const leadFormData = {
    event_action: "submit",
    button_title: "submit",
    product_name: productName,
    product_category: productCategory,
    product_affiliation: productAffiliation || "",
    user_details: {
      email: SHA256(formObject.email?.replace(" ", "")),
      phone: SHA256(formObject.cellPhone?.replace(" ", "")),
      city: formObject.homeProvince || "",
      age: formObject.age || "",
      name: formObject.name || "",
      preferred_time: formObject.preferredTime || "",
    },
  };
  gtmUltils.leadForm(leadFormData);
  const flexibleLeadFormHead = document.getElementById("flexible-form-head");
  if (flexibleLeadFormHead) {
    flexibleLeadFormHead.style.display = "none";
  }
};

export const useTransformEnquiryBlockProps = (
  cmsData,
  publicConfigurations,
  defaultProductName
) => {
  const data = cmsData.reference?.[0] || cmsData?.data;
  const leadFormSection = data?.form_settings?.lead_form_section;
  const { hash } = useSlugs();
  const i18nContext = useContext(I18nContext);
  const { leadFormContextData } = useContext(LeadFormContext);

  const formData = cmsData.reference?.[0] || cmsData?.data;
  const isGeneralForm = formData?.display_setting === "lead_form";

  if (hash) {
    formData?.form_screen?.forEach((field) => {
      if (field.question_field) {
        const matchedAnswer = field.question_field?.answers?.find((a) =>
          a.api_value.includes(hash)
        );

        if (matchedAnswer) {
          field.question_field.default_value = [matchedAnswer];
        }
      }
    });
  }

  const formSetting = formData?.form_settings;
  if (formData?.form_screen?.length > 0) {
    formData.form_screen.forEach((field) => {
      if (
        field.consent_block &&
        field.consent_block?.error_validation_message
      ) {

        field.consent_block.validation_rules = {
          validation_required_field: {
            error_message: field.consent_block.error_validation_message,
          },
        };
        field.consent_block.mandatory = true;
      }
    });
  }

  const services = formSetting?.services;

  const baseUrlConfig = isEmpty(formSetting?.api_url)
    ? {}
    : { baseURL: formSetting.api_url };

  const formProps = {
    data: {
      ...cmsData,
      reference: [
        {
          ...formData,
          display_setting: "single_page",
        },
      ],
      customApiSubmitForm: async (formObject, api) => {
        try {
          const response = await axios({
            method: "POST",
            ...baseUrlConfig,
            url: formSetting.submission_endpoint,
            data: buildRequestBody({
              formData: {
                ...formObject,
                ...(api?.apiRequestBody || {}),
                ...(formSetting?.api_request_body || {}),
              },
              i18nContext,
              emailTemplates: data.emailTemplates || formSetting.email_template,
              services: isEmpty(services)
                ? ["save_lead_api", "email_to_customer", "email_to_admin"]
                : services,
              csBranchName: cmsData?.publicConfigurations?.csBranchName || publicConfigurations?.csBranchName,
              isGeneralForm,
              productMetadata: {
                ...(cmsData?.productMetadata || {}),
                ...leadFormContextData,
              },
              defaultProductName,
            }),
          });
          if (response.status === 200) {
            handlePushEvent(formObject, i18nContext);
          }
          return [response.status === 200];
        } catch (error) {
          console.log(error);
          return [false, "Something went wrong, please try again later"];
        }
      },
      validateOnSubmit: true,
      validateOnBlur: true,
      strictValidate: true,
    },
  };

  return {
    formProps: formProps as any,
    iconName: leadFormSection?.icon,
    title: leadFormSection?.title,
    description: sanitizeHtml(leadFormSection?.description),
    anchorId: data.anchor_id,
    customUi: {
      // TODO: hard-coded, will be custom later
      backgroundColor: fwdColors.orange,
      textColor: fwdColors.white,
    },
  };
};

export const EnquiryLeadForm = (props) => {
  const appContext = useContext(AppContext);
  const { title, iconName, description, formProps, customUi, anchorId } =
    useTransformEnquiryBlockProps(
      props.data,
      appContext.publicConfigurations,
      ""
    );
  if (appContext?.publicConfigurations?.publicCountryCode === "vn") {
    delete formProps?.data?.customApiSubmitForm;
  }
  return (
    <StyledBlockContainer
      id={anchorId || "enquiry-block"}
      backgroundColor={customUi.backgroundColor}
      textColor={customUi.textColor}
    >
      <Grid container>
        <HeaderWrapper item xs={12} lg={3}>
          <Icon icon={iconName} size={64} />
          <Title>{title}</Title>
          <Description
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></Description>
        </HeaderWrapper>
        <Grid item xs={12} lg={9}>
          <FormWrapper>
            <CSFlexiForm {...formProps} />
          </FormWrapper>
        </Grid>
      </Grid>
    </StyledBlockContainer>
  );
};

export const EnquiryLeadPopupForm = (props) => {
  const appContext = useContext(AppContext);

  const { title, iconName, description, formProps, customUi, anchorId } =
    useTransformEnquiryBlockProps(
      props.data,
      appContext.publicConfigurations,
      props.defaultProductName
    );

  return (
    <StyledBlockContainer
      id={anchorId || "enquiry-popup"}
      backgroundColor={"white"}
      textColor={customUi.textColor}
    >
      <FormWrapper>
        <FormHead id={"flexible-form-head"}>
          <Title>{props.titleCx || title}</Title>
          <Description
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></Description>
        </FormHead>
        <CSFlexiForm {...formProps} />
      </FormWrapper>
    </StyledBlockContainer>
  );
};
