import { CTAProps } from "components/CTAButton";
import { ReferenceBlock } from "components/MergedBlock/merged-block.type";
import { CmsCta } from "types/common.types";

export enum PopupType {
  Popup = "popup",
  Dialog = "dialog",
}

export interface CmsPopup {
  uid: string;
  title: string;
  display_title: string;
  dialog_description: string;
  blocks?: [{
    reference_block: ReferenceBlock
  }];
  primary_button: [CmsCta];
  secondary_button?: [CmsCta];
  popup_type: PopupType;
}

export type PopupButtonHandler = (popupInstance: PopupInstanceData) => void
type PopupId = string;
export type PopupButtonHandlers = {
  primary: PopupButtonHandler
  secondary?: PopupButtonHandler
}

export interface PopupData {
  uid: string;
  title: string;
  description: string;
  blocks: {reference_block: ReferenceBlock}[];
  primaryButton?: CTAProps;
  secondaryButton?: CTAProps;
  buttonsHandler?: PopupButtonHandlers;
  type: PopupType;
}

export type PopupInstanceData = PopupData & { popupId: string };
