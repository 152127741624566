import { transformCTAButton, useCTAProps } from "components/CTAButton";
import { ReactComponentLike } from "prop-types";
import { useEffect, useMemo, useState } from "react";
import eventBus from "services/event-bus";
import { useI18nContext } from "utils/hooks";
import { v4 } from "uuid";
import { Popup } from "./Popup";
import { CmsPopup, PopupButtonHandlers, PopupData } from "./Popup.type";

type PopupComponentUid = string;
let listeners = [] as any[];

const memory = {
  popups: [] as {
    id: string;
    data: any;
    templateId: PopupComponentUid;
  }[],
  template: {
    default: Popup,
  },
};

function subscribePopupStore(listener: any) {
  listeners = [...listeners, listener];

  return () => {
    listeners = listeners.filter((l) => listener !== listener);
  };
}

export function registerPopup(
  componentBlockUid: string,
  component: ReactComponentLike
) {
  memory.template[componentBlockUid] = component;
}

function emitChange() {
  const newMemory = { ...memory };
  for (const listener of listeners) {
    listener(newMemory);
  }
}

export function showPopup<T extends PopupData = PopupData>(
  popupData: T,
  templateId = "default"
) {
  if (!memory.template[templateId]) {
    console.error("Popup template not found");
    return;
  }

  const id = v4();
  memory.popups.push({
    id,
    templateId,
    data: popupData,
  });

  emitChange();
  const dismiss = () => dismissPopup(id);

  return dismiss;
}

export function getDismissEventName(popupId: string) {
  return `dismiss-popup:${popupId}`;
}

export function onDismissPopup(
  popupId: string,
  handler: EventListenerOrEventListenerObject
) {
  const eventName = getDismissEventName(popupId);

  eventBus.on(eventName, handler);
  const removeHandler = () => eventBus.off(eventName, handler);
  return removeHandler;
}

export function dismissPopup(popupId: string) {
  eventBus.dispatch(getDismissEventName(popupId));

  // delay removing for dialog dismiss animation
  setTimeout(() => {
    memory.popups = memory.popups.filter((p) => p.id !== popupId);

    emitChange();
  }, 500);
}

export function getActivePopup() {
  return [...memory.popups];
}

export function usePopupInternal() {
  const [state, setState] = useState(memory);

  useEffect(() => {
    const unsubscribe = subscribePopupStore(setState);
    return () => unsubscribe();
  }, [state]);

  return state;
}

export function translatePopupProps(cmsData: CmsPopup) {
  return {
    uid: cmsData.uid,
    type: cmsData.popup_type,
    title: cmsData.display_title,
    description: cmsData.dialog_description,
    blocks: cmsData.blocks || [],
    primaryButton: cmsData.primary_button?.[0]
      ? {
          ...transformCTAButton(cmsData.primary_button[0])!,
          triggeredEventName: `popup:primary-button:${cmsData.uid}`,
        }
      : undefined,
    secondaryButton: cmsData.secondary_button?.[0]
      ? {
          ...transformCTAButton(cmsData.secondary_button[0])!,
          triggeredEventName: `popup:secondary-button:${cmsData.uid}`,
        }
      : undefined,
  };
}

export function usePopupProps(cmsData: CmsPopup) {
  const primaryButton = useCTAProps(cmsData.primary_button[0]);
  const secondaryButton = useCTAProps(cmsData.secondary_button?.[0]);

  return useMemo(() => {
    return {
      uid: cmsData.uid,
      type: cmsData.popup_type,
      title: cmsData.display_title,
      description: cmsData.dialog_description,
      blocks: cmsData.blocks,
      primaryButton: {
        ...primaryButton,
        triggeredEventName: `popup:primary-button:${cmsData.uid}`,
      },
      secondaryButton: secondaryButton
        ? {
            ...secondaryButton,
            triggeredEventName: `popup:secondary-button:${cmsData.uid}`,
          }
        : undefined,
    } as Omit<PopupData, "buttonsHandler">;
  }, [
    cmsData.blocks,
    cmsData.dialog_description,
    cmsData.display_title,
    cmsData.popup_type,
    cmsData.uid,
    primaryButton,
    secondaryButton,
  ]);
}
