import { StylableComponent } from "@types";
import { FC, useMemo } from "react";
import {
  CmsReferenceBlock,
  REFERENCE_BLOCK_COMPONENT_MAP,
} from "./reference-block.type";

interface ReferenceBlockProps extends StylableComponent {
  block: CmsReferenceBlock;
  useParentPadding?: boolean;
  isHorizontalVertical?: boolean;
}

const REFERENCE_KEYS = [
  "reference",
  "reference_2",
  "reference_3",
  "reference_4",
  "reference_5",
  "reference_6",
];

export const ReferenceBlock: FC<ReferenceBlockProps> = ({
  block,
  ...props
}) => {
  const csBlock = useMemo(() => {
    const blockDataKey =
      REFERENCE_KEYS.find((key) => {
        return !!block[key]?.[0];
      }) ?? "";

    const blockData = block[blockDataKey]?.[0];

    const Component =
      REFERENCE_BLOCK_COMPONENT_MAP[blockData?._content_type_uid || ""];

    // remove block from props
    return {
      data: blockData ? { ...blockData, ...(blockData?.global || block) } : {},
      Component: Component || "",
    };
  }, [block]);

  if (!csBlock.Component) {
    return <></>;
  }

  return (
    <div className={props.className}>
      <csBlock.Component
        data={{
          ...csBlock.data,
          ...props,
        }}
      />
    </div>
  );
};
