import { FlexibleFormValidationType } from "./common.type";
import { CSMedia, CSFlexibleOTPSuccessScreen } from "./csTypes";
import { CmsFormSummary } from "./formSummary.type";
import { CmsPopup } from "shared-components/Popup/Popup.type";

type Nullable<T> = null | T;

export enum CSFormPageFieldType {
  // FormFields
  // Text
  TextField = "text_input_field",
  // Select
  SelectField = "dropdown_field",
  // Date
  DatePickerField = "datepicker_field",
  // List
  ListField = "question_field",
  // Phone number
  PhoneNumberField = "number_field",
  // Check box
  ConsentField = "consent_block",
  // Conditional Consent block
  ConditionalConsentField = "conditional_consent_block",
  // Radio
  RadioField = "radio_field",

  ConditionalButtonField = "conditional_button_field",
  ConditionalBlock = "conditional_block",
}

export enum CSFormPageBlockType {
  IntroductionBlock = "section_block",
  MarketingBlock = "marketing_block",
  SectionTitle = "title_block",
  FreeText = "freetext_block",
  ConditionalDisplayBlock = "conditional_display_block",
  DocumentInfoBlock = "document_info_block",
  UploadDocumentBlock = "upload_document_block",
  SummaryBlock = "summarise_block",
  // Button
  ButtonBlock = "button",
  UploadNextButton = "upload_next_button",
}

export type CSFormPageType = CSFormPageFieldType & CSFormPageBlockType;

export interface CSFlexibleForm {
  uid: string;
  title: string;
  display_setting: string;
  form_screen: Partial<CSFlexibleFormBlocks>[];
  form_settings: CSFlexibleFormSettings;

  // thank_you_and_error_screen: Partial<CSFlexibleResultPage>[];

  otp_verification_screen: CSFlexibleOTPVerificationScreen;
  thank_you_screen: CSFlexibleSuccessResultPage;
  error_screen: CSFlexibleFailResultPage;

  page_seo_settings: CSPageSeoSettings;
  // top_navigation: [];
  // site_footer: [];
}

export enum CSFlexibleResultPageType {
  Success = "thank_you_screen",
  Fail = "error_screen",
}

export interface CSFlexibleFailResultPage {
  button_label: string;
  button_link: string;
  description: string;
  title: string;
  background_color: string;
  background_desktop: null | CSMedia;
  background_mobile: null | CSMedia;
  image: null | CSMedia;
}

export interface CSFlexibleSuccessResultPage {
  background_color: string;
  background_desktop: null | CSMedia;
  background_mobile: null | CSMedia;
  description: string;
  image: null | CSMedia;
  title: string;
}

export interface CSFlexibleOTPVerificationScreen {
  description: string;
  title: string;
  subtitle: string;
  enabled: boolean;
  length_of_otp: number;

  resend_text: string;
  resend_button: string;

  success_screen: null | CSFlexibleOTPSuccessScreen;
}

export type CSFormBlocks =
  | CSFormIntroductionBlock
  | CSFormMarketingBlockBlock
  | CSFormSectionTitle
  | CSFormButtonBlock
  | CSFormConditionalDisplayBlock
  | CSFormFreeTextBlock
  | CSFormDocumentInfoBlock
  | CSFormUploadDocumentBlock
  | CSFormUploadNextButton
  | CSSummaryBlock;

export type CSFormFields =
  | CSFormTextField
  | CSFormSelectField
  | CSFormDatePickerField
  | CSFormListField
  | CSFormPhoneNumberField
  | CSFormConsentField
  | CSFormConditionalConsentField
  | CSFormRadioField
  | CSFormConditionalBlock
  | CSFormConditionalButtonField;

export type CSFormBlock = CSFormBlocks | CSFormFields;

export interface CSFlexibleFormBlocks {
  // Block
  [CSFormPageBlockType.IntroductionBlock]: CSFormIntroductionBlock;
  [CSFormPageBlockType.MarketingBlock]: CSFormMarketingBlockBlock;
  [CSFormPageBlockType.SectionTitle]: CSFormSectionTitle;
  [CSFormPageBlockType.ButtonBlock]: CSFormButtonBlock;
  [CSFormPageBlockType.FreeText]: CSFormFreeTextBlock;
  [CSFormPageBlockType.ConditionalDisplayBlock]: CSFormConditionalDisplayBlock;
  [CSFormPageBlockType.UploadDocumentBlock]: CSFormUploadDocumentBlock;
  [CSFormPageBlockType.UploadNextButton]: CSFormUploadNextButton;
  // Form fields
  [CSFormPageFieldType.TextField]: CSFormTextField;
  [CSFormPageFieldType.SelectField]: CSFormSelectField;
  [CSFormPageFieldType.DatePickerField]: CSFormDatePickerField;
  [CSFormPageFieldType.ListField]: CSFormListField;
  [CSFormPageFieldType.PhoneNumberField]: CSFormPhoneNumberField;
  [CSFormPageFieldType.ConsentField]: CSFormConsentField;
  [CSFormPageFieldType.ConditionalConsentField]: CSFormConditionalConsentField;
  [CSFormPageFieldType.RadioField]: CSFormRadioField;
  [CSFormPageFieldType.ConditionalBlock]: CSFormConditionalBlock; // Conditional Field
}

export interface CSFormIntroductionBlock {
  type?: CSFormPageBlockType.IntroductionBlock;
  header_logo: Nullable<CSMedia>;
  icon: Nullable<CSMedia>;
  heading: string;
  description: string;
  custom_styling?: Object;
}

export interface CSFormMarketingBlockBlock {
  type?: CSFormPageBlockType.MarketingBlock;
  usp_design: string;
  title: string;
  description: string;
  banner_image: Nullable<CSMedia>;
  collapsed: boolean;
  usp: {
    usp_icon: Nullable<CSMedia>;
    usp_title: string;
    usp_description: string;
  }[];
  custom_styling?: Object;
}

export interface CSFormFreeTextBlock {
  type?: CSFormPageBlockType.FreeText;
  text_block: string;
  style: Object;
  design_settings: {
    bottom_margin: null | number;
    top_margin: null | number;
  };
}

export interface CSFormConditionalBlock {
  type?: CSFormPageFieldType.ConditionalBlock;
  title: string;
  pretick: boolean;
  conditions: {
    match_field_api_name: string;
    match_field_api_value: string;
    content: string;
    default: boolean;
  }[];
  error_validation_message: string;
  api_parameter_name?: string;
  mask_pii_data_for_ga?: boolean;
  validation_rules?: any;
}

export interface CSFormConditionalDisplayBlock {
  type?: CSFormPageBlockType.ConditionalDisplayBlock;
  rule_set: {
    key: string;
    value: string;
    form_reference: any;
  }[];
}

export interface CSFormDocumentInfoBlock {
  type?: CSFormPageBlockType.DocumentInfoBlock;
  key: string;
  title: string;
  description: string;
  content: {
    data: {
      description: string;
    }[];
  }[];
}

export interface CmsFormConsentItem {
  _metadata: { uid: string };
  id: any;
  label: string;
  required: boolean;
  error_message: string;
}

export interface CmsFormSubmitBlock {
  button: {
    label: string;
    icon: string;
    url?: string;
  };
  consent_items: CmsFormConsentItem[];
  // make it flexible for future requirements
  success_action: [
    {
      navigate: {
        url: string;
      };
    }
  ];
  failure_dialog: [CmsPopup];
}
export interface CSSummaryBlock {
  type?: CSFormPageBlockType.SummaryBlock;
  reference: [CmsFormSummary | CmsFormSubmitBlock];
}

export interface CSFormUploadDocumentBlock {
  type?: CSFormPageBlockType.UploadDocumentBlock;
  key: string;
  variant: "narrow" | "full-size";
  config: string;
  title: string;
  upload_data: {
    title: string;
    description: string;
    download_content: {
      text: string;
      url: string;
    }[];
    upload_content: {
      key: string;
      text: string;
      doc_type_code: string;
      description: string;
      remove_icon: string;
      remove_text: string;
      number_file_required: number;
      success_text: string;
      error_text: string;
      required_error_text: string;
      wrong_format_error_text: string;
      exceed_size_error_text: string;
      full_capacity_text: string;
      remove_error_text: string;
      ga_label: string;
    };
  }[];

  accept_file_format: {
    extension: string;
    thumbnail_image: {
      url: string;
    };
    limit_size: number;
  }[];
  upload_option: "upload-all" | "upload-each";
}

export interface CSFormUploadNextButton {
  type?: CSFormPageBlockType.UploadNextButton;
  title: string;
  key_name: string;
  next_url: {
    title: string;
    url: string;
  };
  fallback_url: {
    title: string;
    url: string;
  };
}

export interface CSFormSectionTitle {
  type?: CSFormPageBlockType.SectionTitle;
  icon: Nullable<CSMedia>;
  title: string;
}

export interface CSFormButtonBlock {
  type?: CSFormPageBlockType.ButtonBlock;
  button_label: string;
  style?: Object;
  icon: string;
  url?: string;
}

// Text field
export interface CSFormTextField {
  type?: CSFormPageFieldType.TextField;
  field_label: string;
  full_width: boolean;
  mandatory: boolean;
  encrypted: boolean;
  hidden?: boolean;
  default_text?: string;
  // error_validation_message: string;
  // validation_rules?: CSFieldValidationRules;
  validation_rules?: CSValidationRules;
  //Partial<FlexibleFormValidationType, CSValidationRule>
  //  {
  //     [key: string]: {
  //         value?: string;
  //         error_message?: string;
  //     }
  // }
  api_parameter_name?: string;
  mask_pii_data_for_ga?: boolean;
  field_class?: string;
}

export interface CSFormSelectField {
  type?: CSFormPageFieldType.SelectField;
  mandatory: boolean;
  encrypted: boolean;
  field_label: string;
  full_width: boolean;

  dropdown: {
    option_label: string;
    option_api_parameter_name: string;
    default_value: boolean;
  }[];
  validation_rules?: any; //CSFieldValidationRules;
  //error_validation_message: string;
  api_parameter_name?: string;
  mask_pii_data_for_ga?: boolean;
}

export interface CSFormDatePickerField {
  type?: CSFormPageFieldType.DatePickerField;
  mandatory: boolean;
  label_field: string;
  placeholder: string;
  validation_rules?: CSValidationRules;
  api_parameter_name?: string;
  mask_pii_data_for_ga?: boolean;

  minAge?: number;
  maxAge?: number;
  minDate?: string;
  maxDate?: string;
  futureDateAllowed?: boolean;
}

export interface CSFormListField {
  type?: CSFormPageFieldType.ListField;
  mandatory: boolean;
  field_label: string;
  api_parameter_name?: string;
  mask_pii_data_for_ga?: boolean;
  answers: {
    option_label: string;
    api_value: string;
  }[];
  validation_rules?: any; //CSFieldValidationRules;,
  multiple_answers_allowed?: boolean;
  default_value?: {
    option_label: string;
    api_value: string;
  }[];
}

export interface CSFormPhoneNumberField {
  type?: CSFormPageFieldType.PhoneNumberField;
  mandatory: boolean;
  encrypted: boolean;
  field_label: string;
  country_code: string;
  validation_rules?: any;
  api_parameter_name?: string;
  mask_pii_data_for_ga?: boolean;
}

export interface CSFormRadioField {
  type?: CSFormPageFieldType.RadioField;
  mandatory: boolean;
  field_label: string;
  api_parameter_name?: string;
  items: {
    item_label: string;
    item_value: string;
  }[];
}

export interface CSFormConditionalButtonField {
  type?: CSFormPageFieldType.ConditionalButtonField;
  title: string;
  pretick: boolean;
  conditions: {
    match_field_api_name: string;
    match_field_api_value: string;
    content: string;
    default: boolean;
  }[];
  error_validation_message: string;
  api_parameter_name?: string;
  mask_pii_data_for_ga?: boolean;
  validation_rules?: any;
}

// Check box field
export interface CSFormConsentField {
  type?: CSFormPageFieldType.ConsentField;
  pretick: boolean;
  content: string;
  error_validation_message: string;
  api_parameter_name?: string;
  mask_pii_data_for_ga?: boolean;
  validation_rules?: any;
}

export interface CSFormConditionalConsentField {
  type?: CSFormPageFieldType.ConditionalConsentField;
  pretick: boolean;
  conditions: {
    match_field_api_name: string;
    match_field_api_value: string;
    content: string;
    default: boolean;
  }[];
  error_validation_message: string;
  api_parameter_name?: string;
  mask_pii_data_for_ga?: boolean;
  validation_rules?: any;
}

// // Phone number field
// export interface CSFormPhoneNumberField {
//     type?: CSFormPageBlockType.PhoneNumberField;
//     field_label: string;
//     error_validation_message: string;
//     max_length: number;
//     min_length: number;
// }

// Email field
// export interface CSFormEmailField {
//     type?: CSFormPageBlockType.EmailField;
//     prefix: string;
//     field_label: string;
//     error_validation_message: string;
// }

interface CSFieldValidationRules {
  [key: string]: {
    value?: string;
    error_message?: string;
  };
}

export enum CSFormService {
  SaveLeadAPI = "save_lead_api",
  EmailToAdmin = "email_to_admin",
  EmailToCustomer = "email_to_customer",
}

export enum ClaimFormTypeEnum {
  NormalFlow = "normal_form",
  ExpressFlow = "express_form",
}

export interface CSFlexibleFormSettings {
  trigger_email_on_submit: boolean;
  email_template: any[];
  submission_endpoint: string;
  send_email_endpoint: string;
  background_color: null | string;
  background_desktop: null | CSMedia;
  background_mobile: null | CSMedia;
  full_width: boolean;
  hide_header: boolean;
  display_close_icon: boolean;
  api_url: string;
  api_request_header: Object;
  api_request_body: Object;
  api_error_message: string;
  url_query_string: {
    target?: string;
    params: string[];
    setReadOnly?: boolean;
  }[];
  company_logo: null | CSMedia;
  claim_form_section: {
    type: ClaimFormTypeEnum;
    fallback_url: string;
    error_url: string;
  };
  lead_form_section: {
    title: string;
    icon: string;
    description: string;
    alignment: "left" | "right";
  };
  services: CSFormService[];
  form_configs?: Object;
  api_request_header_configs: string;
}

interface CSPageSeoSettings {
  page_title: string;
  page_description: string;
  anti_flicker_script: boolean;
  external_page: boolean;
  seo: {
    canonical_url: string;
    meta_title: string;
    meta_keywords: string;
    meta_description: string;
    ld_json: {};
    is_hide_from_xml: boolean;
  };
  open_graph: {
    og_title: string;
    og_description: string;
    og_image: null | string;
    og_url: string;
  };
  top_navigation: any[];
  site_footer: any[];
}

export type CSValidationRules = Partial<{
  [key in FlexibleFormValidationType]: {
    value?: string | number | boolean | Array<any> | Object;
    error_message?: string;
  };
}>;

// export interface CSValidationRule {
//     value?: any;
//     error_message?: string;
// }
