import { PageEclaim } from "constants/pages.constants";
import { CSFormPageFieldType } from "./types/contentstack.types";
import CryptoJS from "crypto-js";
import { getWindow } from "@utils";
import isBoolean from "lodash/isBoolean";

export function isFormField(formFieldType) {
  return Object.values(CSFormPageFieldType).includes(formFieldType);
}

export function handleEncryptWithKey(plaintext, key) {
  return CryptoJS.AES.encrypt(plaintext, key).toString();
}

export const getUniversalClaimData = () => {
  // return {
  //   beneficial: {
  //     value: "yes",
  //   },
  // } as Record<string, any>;
  return JSON.parse(
    getWindow()?.sessionStorage.getItem(PageEclaim.UniversalFormInput) || '{}',
  );
};

export const getClaimFieldValue = (fieldKey: string, defaultValue: any = undefined) => {
  const data = getUniversalClaimData();
  const keys = fieldKey?.split(' ') || [];

  if (keys.length > 1) {
    return keys
      .map((key) => (data?.[key] ? data[key] : null))
      .filter((v) => !!v)
      .join(' ');
  }

  return data[fieldKey] || defaultValue;
};

function checkRule(rule: { fieldName: string, value: string }) {

  let value = getClaimFieldValue(rule.fieldName);
  if (value.value) {
    value = value.value;
  }
  return value === rule.value;

}
export function checkFieldShouldSubmit(fieldKey: string, formRule: object, isDocuments: boolean) {
  const rules = isDocuments ? formRule?.["documents"]?.[fieldKey] : formRule?.[fieldKey];
  if (!rules) return true;
  return isBoolean(rules.show)
    ? rules.show
    : rules.show
      ? rules.show.every((rule) => checkRule(rule))
      : rules.visible
        ? rules.visible.every(rule => checkRule(rule))
        : true;
}
export function checkShouldReplaceValue(fieldName: string, formRule: object, formData: object) {
  const defaultValue = formData[fieldName]
  const rule = formRule[fieldName]
  if (!rule) return defaultValue
  if (!rule.replaceValue) return defaultValue
  let value = defaultValue
  let replaced = false
  
  rule.replaceValue.forEach(r => {
    if (replaced) return

    if (r.when.every(p => {
      let value = getClaimFieldValue(p.fieldName, {});
      if (value.value) {
        value = value.value;
      }

      const result = p.op === "neq" ? p.value !== value : p.value === value
      return result
    })) {
      value = r.replacedValue
      replaced = true
    }
  })
  return { value, shouldReplace: replaced }
}