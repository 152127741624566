import axios from "axios";
import { getConfiguration } from "page-services/configuration.service";

const API_URL = `${getConfiguration(
  "NEXT_PUBLIC_API_URL",
  process.env.NEXT_PUBLIC_API_URL
)}`;

const API_AUTH_BASE64 =
  getConfiguration(
    "NEXT_PUBLIC_API_AUTH_BASE64",
    process.env.NEXT_PUBLIC_API_AUTH_BASE64
  ) || "";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Basic ${API_AUTH_BASE64}`
  },
  proxy: false
});

export default axiosInstance;
