export type Maybe<T> = T | null | undefined;
import axios, { Method } from "axios";
export interface ColorPair {
  background: string;
  foreground: string;
}

export type ApiRequest = {
  endpoint: string;
  method: Method;
  body: any;
  headers: {
    value: {
      key: string;
      value: string;
    }[];
  };
};

export type PublicConfigurations = {
  publicRootUrl?: string;
  publicExportSite?: string;
  publicCountryCode?: string;
  apiAuthBase64?: string;
  googleMapApiKey?: string;
  dtcCampaignId?: string;
  publicApiUrl?: string;
  fCounterUrl?: string;
  fCounterToken?: string;
  csBranchName: string;
  flexibleFormApiKey?: string;
  flexibleFormApiUrl?: string;
};

export interface StylableComponent {
  className?: string;
}

export enum CmsCtaAction {
  Navigate = "navigate",
  TriggerEvent = "trigger-event",
}

export enum CTAButtonVariant {
  Primary = "primary",
  Secondary = "secondary",
}

export enum CTAButtonBackground {
  Orange = "orange",
  White = "white",
}

export interface CmsCta {
  uid: string;
  variant: CTAButtonVariant;
  background: CTAButtonBackground;
  title: string;
  button_title: string
  open_in_new_tab: boolean;
  icon: string;
  ga_label: string;
  gtm_component_name: string;
  url: string;
  button_url: string;
  file?: {
    url: string;
    title: string;
  };
  triggered_event_name?: string;
}
