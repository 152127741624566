import { PublicConfigurations } from "@types";
import isArray from "lodash/isArray";
import { createContext } from "react";

export interface AppContextData {
  publicConfigurations: PublicConfigurations
}

const AppContext = createContext({
  publicConfigurations: {}
} as AppContextData);

export default AppContext;
