import { getWindow } from "@utils";
import { ClaimFormTypeEnum } from "components/FlexiForm/types/contentstack.types";
import { PageEclaim } from "constants/pages.constants";
import get from "lodash/get";
import axios from "utils/axios";

export type RequestDocumentInfo = {
  country: string;
  file: File;
  key: string;
  contentUid: string;
  locale: string;
};

export type ResponseDocumentInfo = {
  key: string;
  name: string;
  extension: string;
  url: string;
};

export type RemoveDocumentInfo = {
  country: string;
  fileName: string;
  key: string;
};

export const uploadDocument = async (
  data: RequestDocumentInfo,
  claimType?: ClaimFormTypeEnum
): Promise<any> => {
  try {
    if (!claimType) return;

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));

    const { policyNumber, claimId } = getClaimData(claimType);

    if (!!claimId && !!policyNumber) {
      formData.append("claimId", claimId);
      formData.append("policyNumber", policyNumber);
      formData.append("claimType", claimType);
    }

    const response = await axios.post(`/api/document/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response?.data?.result) {
      return response.data.result;
    }
  } catch (e) {
    //console.log({ e });
    return;
  }
};

export const removeDocument = async (
  data: RemoveDocumentInfo,
  claimType?: ClaimFormTypeEnum
): Promise<any> => {
  try {
    if (!claimType) return;

    const { policyNumber, claimId } = getClaimData(claimType);

    const response = await axios.delete(
      `/api/document/${data.key}?fileName=${data.fileName}&country=${data.country}&policyNumber=${policyNumber}&claimId=${claimId}&claimType=${claimType}`,
      {}
    );

    if (response?.data?.result) {
      return response.data.result;
    }
  } catch (e) {
    //console.log({ e });
    return;
  }
};

export const getClaimData = (
  claimType?: ClaimFormTypeEnum
): { policyNumber: string; claimId: string } => {
  let policyNumber: string = "";
  let claimId: string = "";

  if (!claimType) {
    return {
      policyNumber,
      claimId,
    };
  }

  switch (claimType) {
    case ClaimFormTypeEnum.ExpressFlow:
      const claimDataKey = getWindow()?.sessionStorage?.getItem(
        PageEclaim.ClaimDataById
      );

      if (claimDataKey) {
        const claimData = JSON.parse(claimDataKey);
        policyNumber = get(claimData, "policy.policyNumber", "");
        claimId = get(claimData, "claimId", "");
      }

      break;

    case ClaimFormTypeEnum.NormalFlow:
    default:
      const claimDataUniversalFormKey = getWindow()?.sessionStorage?.getItem(
        PageEclaim.UniversalFormInput
      );

      if (claimDataUniversalFormKey) {
        const claimData = JSON.parse(claimDataUniversalFormKey);
        policyNumber = get(claimData, "policyNumber", "");
      }

      claimId =
        getWindow()?.sessionStorage?.getItem(PageEclaim.ClaimIdKey) || "";
  }

  return {
    policyNumber,
    claimId,
  };
};
