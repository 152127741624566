import { DisclosurePopupData } from "types/DisclosurePopup.interface";

export enum CSLinkListBlockVariant {
  DEFAULT = "Default",
  MODERN = "Modern",
  MODERN_2 = "modern2"
}

export interface ILinkedList {
  title?: string;
  sub_title?: string;
  paddingBottom: string;
  paddingTop: string;
  backgroundColor?: string;
  anchoringId?: string;
  itemsPerLine?: number;
  topDescription?: string;
  bottomDescription?: string;
  listItemStyles: {
    activeBackgroundColor: string;
    normalBackgroundColor: string;
    borderColor: string;
  };
  listItems: Array<{
    itemTitle: string;
    itemDescription: string;
    nameIcon: string;
    disclosure_popup?: DisclosurePopupData[];
    activeUrl: string;
    fileUrl?: string;
    anchoring: boolean;
    openNewTab: boolean;
  }>;
  showMore: {
    name: string;
    length: string;
  };
  callToActionLink: {
    text: string;
    href: string;
    openNewTab: boolean;
  };
  variant?: CSLinkListBlockVariant;
  useParentPadding?: boolean;
  tabActivated?: number;
}

export interface CSLinkListBlockProps {
  data: {
    title: string;
    sub_title: string;
    padding_bottom: string;
    padding_top: string;
    anchoring_id: string;
    items_per_line: number;
    background_color?: string;
    top_description?: string;
    bottom_description?: string;
    list_item_styles: {
      normal_background_color: string;
      active_background_color: string;
      border_color: string;
    };
    list_item: {
      item_title: string;
      item_description: string;
      item_icon: string;
      disclosure_popup?: DisclosurePopupData[];
      active_url: {
        href: string;
      };
      file?: { url?: string };
      anchoring: boolean;
      open_in_new_tab: boolean;
    }[];
    show_more: {
      name: string;
      length: string;
    };
    call_to_action_link: {
      text: string;
      active_url: string;
      open_new_tab: boolean;
    };
    variant?: CSLinkListBlockVariant;
    useParentPadding?: boolean;
    tabActivated?: number;
  };
}
