import { getWindow, gtmUltils } from "@utils";
import axios, { AxiosResponse } from "axios";
import { getUrlParams } from "components/FlexiForm";
import { transformCSFlexibleForm } from "components/FlexiForm/transform/transform";
import { PageEclaim } from "constants/pages.constants";
import AppContext from "context/AppContext";
import I18nContext from "i18n/context/LanguageContext";
import get from "lodash/get";
import { useRouter } from "next/router";
import { getRequestUrl } from "page-services/helper.service";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { gaEventAction } from "utils/gtm";
import { resolveAbsoluteUrl } from "utils/route";
import { getCountryCode } from "utils/string";
import { useIsHydrating } from "./components/useHydrating";

async function fetchData(url: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get<any>(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching Data:", error);
    throw error;
  }
}

export function getClaimIdFromClaimDataInSessionStorage(): string {
  const claimDataKey = getWindow()?.sessionStorage?.getItem(
    PageEclaim.ClaimDataById
  );

  if (claimDataKey) {
    const claimData = JSON.parse(claimDataKey);
    const claimId = get(claimData, "claimId", "");

    return claimId;
  }

  return "";
}

export function validateClaimValueInSessionStorage(
  key: string,
  value: any
): boolean {
  const claimData = getWindow()?.sessionStorage?.getItem(key);

  try {
    if (typeof value === "string") {
      return claimData === value;
    } else if (typeof value === "boolean") {
      return value ? !!claimData : !claimData;
    } else if (typeof value === "object" && value !== null) {
      const firstKey = Object.keys(value)[0];
      const parsedData = claimData ? JSON.parse(claimData) : {};
      if (firstKey in parsedData) {
        return value[firstKey] ? !!parsedData[firstKey] : !parsedData[firstKey];
      }
    }
  } catch (error) {
    console.error("Error parsing claimData:", error);
    return false;
  }

  return false;
}

export function clearClaimDataInSessionStorage(): boolean {
  getWindow()?.sessionStorage?.removeItem(PageEclaim.ClaimDataById);
  getWindow()?.sessionStorage?.removeItem(PageEclaim.UniversalFormInput);
  getWindow()?.sessionStorage?.removeItem(PageEclaim.ClaimIdKey);
  getWindow()?.sessionStorage?.removeItem(PageEclaim.ClaimPolicyNumberKey);

  return true;
}

function useValidateClaimData({
  data,
  shouldFetchClaimData,
  setFormProps,
  fallbackUrl,
  isLanding,
}) {
  const appContext = useContext(AppContext);
  const i18nContext = useContext(I18nContext);
  const isHydrating = useIsHydrating();

  const router = useRouter();
  const { claimId } = router.query;

  const [isValidated, setIsValidated] = useState<boolean | null>(null);

  const country: string = useMemo(
    () => getCountryCode(i18nContext.countryCode) as string,
    [i18nContext.countryCode]
  );

  const memoizedTransformData = useMemo(() => {
    return (claimData?: any) => {
      const flexiForms = data?.reference;
      if (!flexiForms || flexiForms.length < 1) return null;

      const formCsProps = flexiForms[0];
      const isSteppers = formCsProps.display_setting === "steppers";

      const externalFunc: any = {
        customApiSubmitForm: data.customApiSubmitForm,
        validateOnSubmit: isSteppers || data.validateOnSubmit,
        validateOnBlur: data.validateOnBlur,
        strictValidate: isSteppers || data.strictValidate,
        ...(isSteppers
          ? {
              onCallbackWithoutProceedAPI: (resp: any) => {
                const nextButton = formCsProps?.form_screen?.find(
                  (fs: any) => fs["button"]
                );
                if (nextButton) {
                  window.location.href = nextButton.button?.url || "";
                }
              },
            }
          : {}),
      };

      if (typeof data.onCustomFormSuccess === "function") {
        externalFunc.onFormSuccess = data.onCustomFormSuccess;
      }

      let transformedForm: any = transformCSFlexibleForm(
        formCsProps,
        externalFunc,
        Object.keys(appContext.publicConfigurations).length > 0
          ? appContext.publicConfigurations
          : data.publicConfigurations,
        data.fieldVariant,
        i18nContext,
        claimData
      );

      transformedForm = getUrlParams(
        transformedForm,
        formCsProps.form_settings.url_query_string
      );

      transformedForm.country = country;
      transformedForm.handleGTM = handleGTM;

      setFormProps(transformedForm);
    };
  }, [data, appContext, i18nContext, country]);

  const fetchDataByClaimID = useCallback(async () => {
    const respData: any = await fetchData(
      //getRequestUrl(
      //  `https://1web-dev2.apse1.dgt.np-api.fwd.com/api/claims/${claimId}`
      //)

      getRequestUrl(`/api/claims/${claimId}`)
    );

    if (!respData?.result?.policy?.policyNumber) {
      setIsValidated(false);
      return;
    }

    const universalFormInputKey = getWindow()?.sessionStorage?.getItem(
      PageEclaim.UniversalFormInput
    );

    if (respData.result && respData.result.policy && respData.result.claimId) {
      getWindow()?.sessionStorage?.setItem(
        PageEclaim.ClaimPolicyNumberKey,
        respData.result.policy.policyNumber || ""
      );
      getWindow()?.sessionStorage?.setItem(
        PageEclaim.ClaimDataById,
        JSON.stringify(respData.result)
      );
      getWindow()?.sessionStorage?.setItem(
        PageEclaim.ClaimIdKey,
        respData.result.claimId
      );
    }

    if (!universalFormInputKey) {
      getWindow()?.sessionStorage?.setItem(PageEclaim.UniversalFormInput, "{}");
    }

    memoizedTransformData(respData.result);
    setIsValidated(true);
  }, [claimId, memoizedTransformData]);

  const handleGTM = useCallback((gaLabel: string) => {
    gtmUltils.fileUpload({
      event_action: gaEventAction.button_click,
      button_title: gaLabel,
      component_name: "FlexiForm",
    });
  }, []);

  const storedClaimId = getClaimIdFromClaimDataInSessionStorage();

  useEffect(() => {
    if (isHydrating || !router.isReady) {
      setIsValidated(null);
      return;
    }

    if (!shouldFetchClaimData) {
      memoizedTransformData();
      setIsValidated(true);
      return;
    }

    if (!!storedClaimId) {
      if (!claimId || claimId === storedClaimId) {
        memoizedTransformData();
        setIsValidated(true);
        return;
      }
    } else {
      if (!claimId) {
        setIsValidated(false);
        return;
      }
    }

    clearClaimDataInSessionStorage();
    fetchDataByClaimID();

    if (!isLanding && !!fallbackUrl) {
      router.push(
        resolveAbsoluteUrl(
          !!claimId ? `${fallbackUrl}?claimId=${claimId}` : fallbackUrl,
          i18nContext
        )
      );
    }
  }, [shouldFetchClaimData, isHydrating, claimId, router.isReady]);

  return useMemo(
    () => ({
      isValidated,
      claimId: (claimId as string) || storedClaimId || "",
    }),
    [isValidated]
  );
}

export default useValidateClaimData;
